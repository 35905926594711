
.page-home {
    padding: 16px;
    padding-top: 50px;

    .disabled-template {
        opacity: 0.4;
        filter: saturate(0);
    }

    h1 + .list-group {
        margin-top: 20px;
    }

    .pageid {
        color: #888;
        font-size: 11px;
    }

    .disabled-bots {
        .row-picture {
            vertical-align: middle;
        }
    }

    .header-subtext {
        margin-top: -35px;
        margin-bottom: 35px;
    }

    .list-group-bots {
        .default-adornments {
            color: orange
        }

        .btn-danger, .btn-info, .btn-success {
            position: absolute;
            margin-left: -70px;
            cursor: pointer;
        }

        .btn-info {
            color: black;
        }

        .btn__unlink {
            margin-top: 45px;
            margin-left: -73px;
        }

        .list-group-item {
            width: 300px;
            margin-right: 10px;
            margin-bottom: 10px;
            height: 150px;
            display: inline-block;
            border-radius: 5px;
            border: 1px solid rgba(#000, .2);
            text-align: center;
            vertical-align: top;
            box-sizing: border-box;
            padding: 30px;

            &:not(.no-hover):hover {
                background: $brand-primary;
                color: #fff;

                h4, p {
                    color: #fff;
                }
            }

            &.is-botstore {
                background: rgba(0, 132, 255, 0.2);
            }

            h4 {
                padding-top: 10px;
                line-height: 1.1em;
                font-size: 14px;
                font-weight: 600;
            }

            .row-picture {
                img {
                    display: inline-block;
                    margin: 0;
                }

                padding: 0;
                margin: 0;
                display: block;
                text-align: center;
            }
        }

        &.bot-templates {
            .list-group-item-heading {
                margin-bottom: 0;
            }

            .list-group-item {
                height: 200px;
                width: 200px;
                padding: 10px 20px;
            }

            h4 {
                font-size: 18px;
                font-weight: 600;

                &:first-letter {
                    text-transform: uppercase;
                }
            }

            p {
                font-size: 12px;
                line-height: 1.3em;
            }

            img {
                height: 80px;
                margin: 10px;
                background: transparent;
            }
        }

        .workplace-group-item {
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-block;
            border-radius: 5px;
            border: 1px solid rgba(#000, .2);
            text-align: center;
            vertical-align: top;
            box-sizing: border-box;
            height: 200px;
            width: 200px;
            padding: 0;

            &.from-scratch {
                &:hover {
                    i {
                        color: #ffffff;
                    }
                }

                i {
                    color: #0084ff;
                    font-size: 6em;
                    height: 80px;
                    margin: 10px;
                    margin-top: 45px;
                }
            }

            h4 {
                padding-top: 10px;
                line-height: 1.1em;
                font-size: 14px;
                font-weight: 600;

                &:first-letter {
                    text-transform: uppercase;
                }
            }

            p {
                font-size: 12px;
                line-height: 1.3em;
            }

            .template-icon {
                height: 80px;
                margin: 10px;
                background: transparent;
            }

            .template-container {
                height: 160px;
                border-bottom: 1px solid rgba(#000, .2);

                &.extend-full {
                    height: 200px;
                }
            }

            .learnmore-container {
                height: 40px;
                color: #333333;

                .learn-more {
                    line-height: 40px;
                    margin: 0;
                }
            }
        }

        .hoverable {
            &:hover {
                background: $brand-primary;
                color: #fff;
                cursor: pointer;
                text-decoration: none;
            }
        }
    }


    .recent-bots {
        .list-group-item {
            height: auto;
            vertical-align: middle;
            padding: 0;

            &.is-not-yours:hover {
                background-color: red;
            }

            &.is-not-yours {
                color: red;
            }

            .row-picture {
                display: inline-block;
                vertical-align: middle;
            }

            .row-content {
                padding-left: 16px;
                text-align: left;
                vertical-align: middle;
            }
        }
    }

    .confirm {
        margin: 2em 0 1em 0;
        font-size: 14px;
    }

    .btn.cancel {
        margin-left: 24px;
    }

    input[type=file]:not(.messagebuilder-upload) {
        opacity: 1;
        position: relative;
    }

    .page-unavailable {
        filter: grayscale(100%);
        opacity: 0.75;
    }
}

