.page-userattributes {
  .file-drop-area {
    position: relative;
    display: flex;
    align-items: center;
    width: 450px;
    max-width: 100%;
    padding: 25px;
    border: 1px dashed rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    transition: 0.2s;
    margin-bottom: 2rem;

    &.is-active {
      background-color: rgba(0, 0, 0, 0.05);
    }

    .fake-btn {
      flex-shrink: 0;
      background-color: rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 8px 15px;
      margin-right: 10px;
      margin-left: 10px;
      font-size: 12px;
    }

    .file-msg {
      font-size: small;
      font-weight: 300;
      line-height: 1.4;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 0;
      &:focus {
        outline: none;
      }
    }

    .progress {
      height: 20px;
      width: 100%;
    }
  }


  a.btn.btn-primary[disabled="disabled"] {
    background-color:lightgray;
  }
  .disabled-text {
    color:lightgray;
    cursor:not-allowed;
  }
}
