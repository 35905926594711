.panel.well.panel-nux {
  ol {
    margin:0;
    padding:0;
  }
  li {
    font-size:16px;
    line-height:2em;
    background:url('/public/img/icon-arrow-large-@2x@2x.png') no-repeat;
    background-position:left 10px;
    background-size:15px auto;
    list-style:none;
    padding-left:20px;
    a {
      text-decoration:none;
    }
    &.done {
      background-image:url('/public/img/icon-bulletpoint-@2x.png');
      text-decoration: line-through;
    }
    &.done a {
      text-decoration: line-through;
      border-bottom:none;
    }
    a, i {
      display:inline-block;
      vertical-align: middle;
    }
  }
}

.get-started-page {
  .panel.well {
    width:350px;
    display:inline-block;
    vertical-align: top;
  }
}

.unavailable-bot-modal, .create-bot-modal{
  background: rgba(40, 44, 55, 0.98);
  color: #FFF;
  border-radius: 0;
  display: none;
  overflow: auto;
  padding: 2px;
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5500;

  .unavailable-cta {
    font-size: 14px;
    a {
      color: white;
    }

    i.material-icons {
      vertical-align: middle;
    }
  }

  .content{
    font-family: "Poppins", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    margin: 30px;

    input[type='text'] {
      background-color: transparent;
      display: block;
      width: 100%;
      text-align:left;
      border: 0;
      border-bottom: 1px solid;
      padding-bottom: 0.5em;
      font-size: 1.5em;
      color: #ffffff;
    }
  }

  .form-footer {
    text-align: center;
  }

  &.modal-visible {
    display: block;
  }
}

.WorkplaceRequired {
  &__title {
    font-size: 22px;
  }

  &__subtext {
    font-size: 15px;
    line-height: 67px;
  }
}

.create-bot-modal {
  max-width: 400px;

  .h4 {
    text-align: center;
  }

  .checkbox label {
    margin-left: 25px;
    font-size: 15px;
    line-height: 16px;
    color: white;
  }

  [type='checkbox'].checkbox-inverted + .checkbox-material {
    margin-left: -25px;

    .check {
      border: 2px solid rgba(255, 255, 255, .54);
      background-color: white;
    }
  }

  .help-text {
    margin-left: 25px;
    margin-top: -10px;

    a {
      color: white;
      font-size: 15px;
    }
  }

  #form-errors {
    margin-top: 15px;
  }

  .form-buttons {
    display: flex;
    justify-content: center;;
    margin-top: 50px;
  }

  .cancel-modal-button {
    color: #fff !important;
  }
}
