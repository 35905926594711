// Padding
.pl-\[5px\] {
    padding-left: 5px;
}
.pl-0 {
    padding-left: 0px;
}
.pt-4 {
    padding-top: 1rem /* 16px */;
}
.pt-10 {
    padding-top: 2.5rem /* 40px */;
}
.pb-5 {
    padding-bottom: 1.25rem /* 20px */;
}

// Margin
.m-0 {
    margin: 0px;
}
.m-auto {
    margin: auto;
}
.m-2\.5 {
    margin: 0.625rem /* 10px */;
}
.my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.my-5 {
    margin-top: 1.25rem /* 20px */;
    margin-bottom: 1.25rem /* 20px */;
}
.my-6 {
    margin-top: 1.5rem /* 24px */;
    margin-bottom: 1.5rem /* 24px */;
}
.my-8 {
    margin-top: 2rem /* 32px */;
    margin-bottom: 2rem /* 32px */;
}
.mx-0 {
    margin-left: 0px;
    margin-right: 0px;
}
.mx-\[30px\] {
    margin-left: 30px;
    margin-right: 30px;
}
.mb-2\.5 {
    margin-bottom: 10px;
}
.mb-4 {
    margin-bottom: 1rem /* 16px */;
}
.mx-8 {
    margin-left: 2rem /* 32px */;
    margin-right: 2rem /* 32px */;
}
.mb-\[15px\] {
    margin-bottom: 15px;
}
.mb-\[35px\] {
    margin-bottom: 35px !important;
}
.mb-\[1em\] {
    margin-bottom: 1em;
}
.mb-5 {
    margin-bottom: 1.25rem /* 20px */;
}
.mr-\[5px\] {
    margin-right: 5px;
}
.mt-0 {
    margin-top: 0px;
}
.mt-2\.5 {
    margin-top: 0.625rem /* 10px */;
}
.mt-4 {
    margin-top: 1rem /* 16px */;
}
.mt-5 {
    margin-top: 1.25rem /* 20px */;
}
.mt-6 {
    margin-top: 1.5rem /* 24px */;
}
.mt-12 {
    margin-top: 3rem /* 48px */;
}
.mt-\[2em\] {
    margin-top: 2em;
}
.mt-\[25px\] {
    margin-top: 25px;
}
.-mt-\[56px\] {
    margin-top: -56px;
}
.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem /* 10px */ * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem /* 10px */ * var(--tw-space-y-reverse));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem /* 8px */ * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem /* 8px */ * calc(1 - var(--tw-space-x-reverse)));
}

// Background
.bg-\[#ffe8cc\] {
    background-color: #ffe8cc;
}
.bg-\[pink\] {
    background-color: pink !important /* #ffc0cb */;
}
.bg-\[lightblue\] {
    background-color: lightblue !important /* #add8e6 */;
}
.bg-linear-background {
    background-image: linear-gradient(130deg, #ffffff 0%, #dfe9f3 100%);
}
.hover\:bg-\[\#fafafa\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important /* #fafafa */;
}

// Border
.border {
    border-width: 1px;
}
.border-0 {
    border-width: 0px;
}
.border-b-0 {
    border-bottom-width: 0px !important;
}
.group.expanded .group-\[\.expanded\]\:border-b {
    border-bottom-width: 1px !important;
}
.border-spacing-\[5px\] {
    --tw-border-spacing-x: 5px;
    --tw-border-spacing-y: 5px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.border-spacing-x-1 {
    --tw-border-spacing-x: 0.25rem /* 4px */;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.border-separate {
    border-collapse: separate;
}
.border-\[\#efefef\] {
    --tw-border-opacity: 1;
    border-color: rgb(239 239 239 / var(--tw-border-opacity)) /* #efefef */;
}
.border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity)) /* #000000 */;
}
.border-opacity-20 {
    --tw-border-opacity: 0.2;
}
.rounded-\[5px\] {
    border-radius: 5px;
}
.rounded-full {
    border-radius: 9999px;
}
// Height
.h-screen {
    height: 100vh;
}
.h-auto {
    height: auto;
}
.h-\[50px\] {
    height: 50px;
}
.h-\[60px\] {
    height: 60px;
}
.min-h-\[52px\] {
    min-height: 52px;
}
.min-h-\[230px\] {
    min-height: 230px;
}

// List Style
.list-none {
    list-style-type: none;
}

// Width
.w-screen {
    width: 100vw;
}
.w-full {
    width: 100%;
}
.w-10 {
    width: 2.5rem /* 40px */;
}
.w-80 {
    width: 20rem /* 320px */;
}
.w-\[50px\] {
    width: 50px;
}
.w-\[100px\] {
    width: 100px;
}
.w-\[250px\] {
    width: 250px;
}
.w-\[330px\] {
    width: 330px;
}
.w-2\/5 {
    width: 40%;
}
.w-\[90\%\] {
    width: 90%;
}
.max-w-\[192px\] {
    max-width: 192px;
}

// Display
.flex {
    display: flex;
}
.block {
    display: block;
}
.inline {
    display: inline;
}
.inline-grid {
    display: inline-grid;
}
.inline-block {
    display: inline-block;
}
.table-row {
    display: table-row;
}
.empty\:hidden:empty {
    display: none;
}

// Position
.absolute {
    position: absolute;
}
.fixed {
    position: fixed;
}
.relative {
    position: relative;
}
.left-0 {
    left: 0px;
}
.top-\[5px\] {
    top: 5px;
}
.right-\[5px\] {
    right: 5px;
}
.-z-10 {
    z-index: -10;
}


// Opacity
.opacity-0 {
    opacity: 0;
}

// Visibility
.invisible {
    visibility: hidden;
}

// Transition
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.delay-200 {
    transition-delay: 200ms;
}

.cursor-pointer {
    cursor: pointer;
}

// Text Decoration
.underline {
    text-decoration-line: underline;
}
.no-underline {
    text-decoration-line: none;
}

// Alignment
.align-top {
    vertical-align: top !important;
}
.align-bottom {
    vertical-align: bottom !important;
}

.text-center {
    text-align: center;
}

// Color
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity)) /* #ffffff */;
}
.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity)) /* #ef4444 */;
}

// Line Height
.leading-6 {
    line-height: 1.5rem /* 24px */;
}
.leading-\[1\.1em\] {
    line-height: 1.1em;
}

// Clear
.clear-both {
    clear: both;
}

// Overflow
.overflow-x-scroll {
    overflow-x: scroll;
}
.overflow-hidden {
    overflow: hidden;
}

// Text size
.text-sm {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
}
.text-base {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
}
.text-lg {
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
}
.text-\[15px\] {
    font-size: 15px;
}
.text-\[30px\] {
    font-size: 30px;
}
.text-\[100px\] {
    font-size: 100px;
}
.font-semibold {
    font-weight: 600;
}

.origin-top-left {
    transform-origin: top left;
}


// Afters
.group.beta .group-\[\.beta\]\:after\:content-\[\'beta\'\]::after {
    --tw-content: 'beta';
    content: var(--tw-content);
}
.group.beta .group-\[\.beta\]\:after\:text-sm::after {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
}
.group.beta .group-\[\.beta\]\:after\:uppercase::after {
    text-transform: uppercase;
}
.group.beta .group-\[\.beta\]\:after\:font-semibold::after {
    font-weight: 600;
}
.group.beta .group-\[\.beta\]\:after\:text-center::after {
    text-align: center;
}
.group.beta .group-\[\.beta\]\:after\:absolute::after {
    position: absolute;
}
.group.beta .group-\[\.beta\]\:after\:bg-red-700::after {
    --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity)) /* #b91c1c */;
}
.group.beta .group-\[\.beta\]\:after\:w-20::after {
    width: 5rem /* 80px */;
}
.group.beta .group-\[\.beta\]\:after\:h-5::after {
    height: 1.25rem /* 20px */;
}
.group.beta .group-\[\.beta\]\:after\:top-\[7px\]::after {
    top: 7px;
}
.group.beta .group-\[\.beta\]\:after\:-left-5::after {
    left: -1.25rem /* -20px */;
}
.group.beta .group-\[\.beta\]\:after\:text-white::after {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity)) /* #ffffff */;
}
.group.beta .group-\[\.beta\]\:after\:rotate-45::after {
    transform: rotate(-45deg);
}


.whitespace-nowrap {
    white-space: nowrap;
}
