.page-ignoredmessages {
    .description {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 14px;
        font-weight: 400;
    }

    @import './_mui-tabs';

    table.ignoredmessages {
        .btn-container {
            visibility: hidden;
        }

        tr:hover .btn-container {
            visibility: visible;
        }

        .received, .actions {
            white-space: nowrap;
        }

        .actions {
            width: 33%;
        }
    }
}
