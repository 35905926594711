.audience.print {
    > h1 {
        font-size: 30px;
    }

    .topLineStats__metricBox {
        display: inline-block !important;
    }

    .material-icons.topLineStats__infoCircle, .material-icons.graph__infoCircle {
        display: none;
    }

    .widgets .mui-tabs__bar, .widgets .datePicker__container {
        display: none;
    }

    .btn.analyticsDownload__button {
        display: none;
    }

    @media print {
        .topLineStats__delta .statNeutral {
            color: #999 !important;
        }

        .topLineStats__delta .statUp {
            color: #24B685 !important;
        }

        .topLineStats__delta .statDown {
            color: #EA5A5A !important;
        }

        .topLineStats__value {
            color: #0084FF !important;
        }
    }
}


