table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .dt-orderable-asc:after,
table.dataTable thead .dt-orderable-desc:after {
    display: block;
    font-family: 'Material Icons';
    display: inline-block;
    margin-left: 6px;
    vertical-align: -2px;
}
table.dataTable thead .sorting:after,
table.dataTable thead .dt-orderable-asc:after,
table.dataTable thead .dt-orderable-desc:after {
  opacity: 0.2;
  content: "\e053";
  /* sort */
}
table.dataTable thead .sorting_asc:after,
table.dataTable thead .dt-ordering-asc:after {
  content: "\e316";
  /* sort-by-attributes */
}
table.dataTable thead .sorting_desc:after,
table.dataTable thead .dt-ordering-desc:after {
  content: "\e313";
  /* sort-by-attributes-alt */
}
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .dt-orderable-none {
  color: #000;
}


table.dt-rowReorder-float {
  position: absolute !important;
  opacity: 0.8;
  table-layout: fixed;
  outline: 2px solid #888;
  outline-offset: -2px;
  z-index: 2001;
}

tr.dt-rowReorder-moving {
  outline: 2px solid #555;
  outline-offset: -2px;
}

body.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}

table.dataTable td.reorder {
  text-align: center;
  cursor: move;
}

div.dt-container .dt-paging .dt-paging-button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: inherit !important;
    border: 1px solid transparent;
    border-radius: 0;
    border-right: 0;
    background: transparent;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
div.dt-container .dt-paging .dt-paging-button:first-child {
    border-left: 1px solid #ccc;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
div.dt-container .dt-paging .dt-paging-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
div.dt-container .dt-paging .dt-paging-button.current,
div.dt-container .dt-paging .dt-paging-button.current:hover {
    color: white !important;
    border-left: 1px solid #0084ff;
    border-top: 1px solid #0084ff;
    border-bottom: 1px solid #0084ff;
    background-color: #0084ff;
}
div.dt-container .dt-paging .dt-paging-button.current,
div.dt-container .dt-paging .dt-paging-button.current:hover {
    color: white !important;
    border-right: 1px solid #ddd;
}
div.dt-container .dt-paging .dt-paging-button.disabled,
div.dt-container .dt-paging .dt-paging-button.disabled:hover,
div.dt-container .dt-paging .dt-paging-button.disabled:active {
    cursor: default;
    color: rgba(0, 0, 0, 0.5) !important;
    border-color: #eee;
    background: transparent;
    box-shadow: none;
}
div.dt-container .dt-paging .dt-paging-button:hover {
    background-color: #b6b6b6;
    border: 1px solid #b6b6b6;
    color: #303030 !important;
}
div.dt-container .dt-paging .dt-paging-button:active {
    outline: none;
    background-color: #0c0c0c;
    box-shadow: inset 0 0 3px #111;
}
div.dt-container .dt-paging .ellipsis {
    padding: 0 1em;
}
div.dt-container .dt-length,
div.dt-container .dt-search,
div.dt-container .dt-info,
div.dt-container .dt-processing,
div.dt-container .dt-paging {
    color: inherit;
}
