.panel-keyword-form {
	background:#EBF8F4;
	margin-bottom:30px;
	padding:0 20px 10px;
	border-top:1px solid rgba(#000000, .15);
	border-bottom:1px solid rgba(#000000, .15);
	.form-group  {
		width:calc(45% - 50px);
		display:inline-block;
		margin-right:3%;
		margin-top:20px;
		margin-bottom:20px;
		&:nth-child(2) {
			width:100px;
		}
		&:nth-child(3) {
			width:calc(55% - 50px);
		}
		&:nth-child(4) {
			width:calc(35% - 50px);
		}
	}
	#keyword {
		display:inline-block;
		width:calc(100% - 100px);
	}
	input[name='keyword[keyword]'] {
		padding-right: 40px;
	}
}
@media(max-width:1100px) {
	.panel-form.panel-keyword-form {
		.form-group  {
			width:45%;
			margin-right:0;
		}
		#keyword {
			width:100%;
		}
	}
}
@media(max-width:800px) {
	.panel-form.panel-keyword-form {
		.form-group  {
			width:100%;
			margin-right:0;
		}
		#keyword {
			width:100%;
		}
	}
}
.help-block.help-block-info {
	display:block;
	.has-error.is-focused & {
		display:none;
	}

}
