
.page-sequence-management {
    .info-desc {
        margin-bottom: 10px;
    }

    .timezone-selector {
        .widget {
            display: inline-block;
            width: 200px;
        }
    }

	.sequenceManagement {
		@import "modal";


        &__modalContainer {
            position: fixed;
        }
	}

    #makeLiveModalBtn {
        float: right;
    }

    .sequenceManagement__modal {
        padding: 40px;

        h3 {
            text-align: left;
        }

        h4 {
            text-align: left;
            color: gray;
        }
    }

    .modal-spacer {
        margin-top: 3em;
    }

    @import './_mui-tabs';
}
