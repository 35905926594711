// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.text-warning {
  color: $brand-warning;
}
.text-primary {
  color: $brand-primary;
}
.text-danger {
  color: $brand-danger;
}
.text-success {
  color: $brand-success;
}
.text-info {
  color: $brand-info;
}
