.page-organization-authentication {
    .configuration-value {
        margin-top: 7px;
    }
}
.page-organization-authentication-identity-providers {
    .card {
        max-width: calc(100vw/8);
        box-shadow: none;
        border-color: lightgray;
        border-radius: 5px;
        color: lightgray;
        .idpSelection {
            background-color: white;
            border-color: lightgray;
            display: block;
            text-align: center;
            border-radius: 5px;
            box-shadow: 0 1px 5px 1px;
            text-decoration:none;
            img {
                width: 75%;
                margin: 0 auto;
                padding-top: 14px;
            }
            span {
                width: 100%;
                padding-top: 11px;
                padding-bottom: 11px;
                display: block;
                color: grey;
                background: white;
            }
        }
    }
}

