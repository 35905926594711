
.page-segment-management {
	form[name='segment_csv_upload'] {
		.control-label {
			font-size: 16px;
			line-height: 1.428571429;
			font-weight: 400;
		}

		.form-group {
			padding-bottom: 7px;
			margin: 28px 0 0 0;
		}

		.form-group .help-block {
			display: block;
		}
	}

	h2 {
		margin-bottom: 16px;
	}

	.segmentManagement {
		@import "modal";

		&__container {
			text-align: left;

			.form-footer {
				text-align: left;
				display: inline;
			}

			.form-group {
				margin: 0;

				input[type=file] {
					position: relative;
					opacity: 1;
				}
			}
		}

        &__modalButtons > .form-group {
            vertical-align: baseline;
        }

		&__padding_top {
			padding-top: 25px;
		}

		&__cancel {
			display: inline;
		}

		&__options {
			margin-top: 48px;
		}

		&__addUserContainer {
			margin-top: 4em;
		}
	}

	.alignInfoSection {
		padding-top: 32px;
	}

	.bottomBorder {
		border-bottom: 1px solid #e6ebf2;
	}
}
