.previewer .pane {
  border: 1px #ccc solid;
}

.pane {
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}

.hero {
  background-size: cover;
}

/* Android-Messenger */
.messenger-Android-Messenger .pane {
  background: #fafafa;
  font-family: 'Roboto', sans-serif; // TODO: embed the proper font in the page
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  width: 730px; // roughly equivalent to a Galaxy S5
}

.messenger-Android-Messenger .element {
  background: #fff;
  border: 1px #e6e6e6 solid;
  border-radius: 10px;
  display: inline-block;
  overflow: hidden;
  margin: 10px auto;
  padding-right: 8px;
  position: relative;
  width: 522px;
}

.messenger-Android-Messenger .element:first-child {
  margin-left: 104px;
}

.messenger-Android-Messenger .hero {
  height: 276px;
  width: 522px;
}

.messenger-Android-Messenger .textContent {
  font-size: 24px;
  padding: 22px;
}

.messenger-Android-Messenger .textContent .title {
  font-weight: 600;
  padding-bottom: 22px;
}

.messenger-Android-Messenger .textContent .subtitle {
  padding-bottom: 22px;
}

.messenger-Android-Messenger .buttons {
  color: #407cfd;
  padding: 0 22px 22px 22px;
  text-align: right;
  text-transform: uppercase;
}

.messenger-Android-Messenger .fakeButton {
  display: inline-block;
  font-size: 24px;
  padding-left: 30px;
}


.messenger-Android-Messenger .buttons.long {
  position: relative;
  width: 522px;
}

.messenger-Android-Messenger .longList .fakeButton {
  display: block;
  padding: 0 0 22px 0;
}

/* iOS-Messenger */
.messenger-iOS-Messenger .pane {
  background: #fff;
  font-family: 'San Francisco', 'Helvetica Neue', sans-serif; // TODO: embed the proper font in the page
  width: 592px; // roughly equivalent to a iPhone 6
}

.messenger-iOS-Messenger .element {
  background: #fff;
  border: 2px #f2f2f2 solid;
  border-radius: 25px;
  display: inline-block;
  overflow: hidden;
  margin: 10px auto;
  padding-right: 16px;
  position: relative;
  width: 420px;
}

.messenger-iOS-Messenger .element:first-child {
  margin-left: 86px;
}

.messenger-iOS-Messenger .hero {
  background-size: cover;
  height: 220px;
  width: 420px;
}

.messenger-iOS-Messenger .textContent {
  font-size: 17px;
  padding: 16px;
}

.messenger-iOS-Messenger .textContent .title {
  font-weight: 600;
}

.messenger-iOS-Messenger .textContent .title,
.messenger-iOS-Messenger .textContent .subtitle {
  padding-bottom: 15px;
}

.messenger-iOS-Messenger .buttons {
  color: #4479e2;
  text-align: center;
}

.messenger-iOS-Messenger .fakeButton {
  border-top: 2px #f2f2f2 solid;
  font-size: 21px;
  padding: 18px 0;
  width: 420px;
}

/* Facebook, Desktop-Chat-Tab Full View */
/* Naturally, there are at least two different versions of this UI.
   One that is gray-framed and faux-3D, another that is blue-framed
   and more Messenger-familiar. This mocks out the latter of the two. */
.messenger-Desktop-Chat-Tab .pane {
  background: #fff;
  border-top: 26px #4080ff solid;
  height: 273px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 260px;
}

.messenger-Desktop-Chat-Tab .element {
  background: #fff;
  border: 1px #d1d1d1 solid;
  border-top-left-radius: 3px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 10px;
  display: inline-block;
  overflow: hidden;
  margin-right: 16px;
  margin-top: 10px;
  position: relative;
  width: 182px;
}

.messenger-Desktop-Chat-Tab .element:first-child {
  margin-left: 44px;
}

.messenger-Desktop-Chat-Tab .hero {
  background-size: cover;
  height: 89px;
  width: 182px;
}

.messenger-Desktop-Chat-Tab .textContent {
  font-size: 12px;
  padding: 7px;
}

.messenger-Desktop-Chat-Tab .textContent .title {
  font-weight: 600;
}

.messenger-Desktop-Chat-Tab .textContent .title,
.messenger-Desktop-Chat-Tab .textContent .subtitle {
  padding-bottom: 4px;
}

.messenger-Desktop-Chat-Tab .buttons {
  color: #365899;
  text-align: center;
}

.messenger-Desktop-Chat-Tab .fakeButton {
  border-top: 1px #d1d1d1 solid;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0;
  width: 182px;
}

/* Facebook Web Full Desktop-Full-Conversation View */
.messenger-Desktop-Full-Conversation .pane {
  background: #fff;
  border: 1px #d1d1d1 solid;
  overflow-y: auto;
  overflow-x: hidden;
  width: 450px;
}

.messenger-Desktop-Full-Conversation .element {
  background: #fff;
  border: 1px #e6e6e6 solid;
  border-radius: 3px;
  display: inline-block;
  overflow: hidden;
  margin-right: 12px;
  margin-top: 10px;
  width: 253px;
}

.messenger-Desktop-Full-Conversation .element:first-child {
  margin-left: 0px;
}

.messenger-Desktop-Full-Conversation .hero {
  background-size: cover;
  height: 131px;
  width: 252px;
}

.messenger-Desktop-Full-Conversation .textContent {
  font-size: 12px;
  padding: 7px;
}

.messenger-Desktop-Full-Conversation .textContent .title {
  font-weight: 600;
}

.messenger-Desktop-Full-Conversation .textContent .title,
.messenger-Desktop-Full-Conversation .textContent .subtitle {
  padding-bottom: 4px;
}

.messenger-Desktop-Full-Conversation .buttons {
  color: #365899;
  text-align: center;
}

.messenger-Desktop-Full-Conversation .fakeButton {
  border-top: 1px #d1d1d1 solid;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 0;
  width: 252px;
}


