.campaignContainer {
  display: flex;
  flex-direction: row-reverse;
}

.campaignDraggable {
  flex: 1 0 40%;
  padding-top: 25px;
}

.campaignForm {
  flex: 1 0 60%;
  padding: 0 30px;

  &__delay {
    display: flex;
  }

  &__days {
    flex: 1;
    padding-right: 10px
  }

  &__hours {
    flex: 1;
    padding-right: 10px;
  }

  &__minutes {
    flex: 1;
    padding-right: 10px;
  }
}

.campaignCard {
  border-radius: 10px;
  background-color: white;
  text-align: center;

  &:hover {
    cursor: move;
  }

  .mui-dropdown__menu {
    cursor: pointer;
  }

  &__messageTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 280px;
    line-height: 20px;
  }
}

.campaignCard__messageContainer {
  width: 300px;
  height: 100px;
  border: 1px #ccc solid;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.campaignCard__arrow {
  padding: 20px 0;
}

.campaignCard__arrow i {
  width: 27px;
}

.campaignCard__remove i {
  position: absolute;
  right: 7px;
}

.campaignCard__end {
  padding-top: 35px !important;
  width: 100px !important;
  border-radius: 50px !important;
  background-color: red;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
}

.campaignButtons {

  &__container {
    display: flex;
  }

  &__save {
    flex: 9;
  }

  &__cancel {
    padding-top: 28px;
    flex: 1;
  }
}

.campaign__emphasis {
  font-weight: 600;
}


@media only screen and (max-width: 950px) {
  .campaignContainer {
    display: block;
  }
}
