
.messagebuilder-upload-container {
  position: relative;

  .messagebuilder-upload {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    z-index: -1;

    & + label {
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
      cursor: pointer;
    }
  }
}

.upload-modal {
  color: white;
  max-height: 50px;
  line-height: 16px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.8);

  transform: translateX(-50%) translateY(-50%);
}

