.page-customer-chat-plugin {
    a:not(.btn) {
        color: #0084ff;
        text-decoration: underline;
    }
    textarea.code-snippet {
        font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
        border-radius: 3px;
        border-color: lightGray;
        width:100%;
        padding: 10px;
    }
}