
@import '../includes/page-onboarding';

.page-onboarding {
  color: #313131;
  vertical-align: top;
  font-size: 16px;

  h1 {
    font-size: 24px;
  }

  .mt-20 {
    padding-top: 20px;
  }

  .img-container {
    max-height: 300px;
  }

  .btn__facebook-btn {
    margin-right: 10px;
  }

  img.img__help {
    width: 90%;
    height: 90%;
    border: 0;
    margin-left: 50px;

    &.with-border {
      border: 1px solid lightgray;
    }
  }
}
