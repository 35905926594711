.page-signup {
  .modal {
    display: block;
  }

  .login-here-link a {
    font-weight: 600;
    text-decoration: underline;
  }

  .form-group.has-error {
	padding-bottom: 28px;
  }

  .form-group .help-block {
    display: block;
    position: relative;
  }
}
