.sequence-desc {
    font-size: 14px;
    font-weight: 400;
}

.sequence-container {
    display: flex;
}

.sequence-preview-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
}
.sequence-preview-days-selected-container {
    margin-bottom: 10px;

    > div:first-child {
        font-size: 14px;
        font-weight: 600;
    }
}

.sequence-preview-timeline-container {
    flex: 1 auto;
    padding: 90px 40px 0px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    height: 25vh;

    .preview-title {
        font-size: 14px;
        font-weight: 600;
        margin-top: 10px;
    }

    .preview-selected-segment {
        font-weight: 600;
    }

    .preview-calender {
        margin-top: 15px;
    }

    .preview-timeline {
        font-size: 14px;

        > div {
            margin: 15px 0px;
        }

        .Link {
            color: black;
            font-weight: 600;
            text-decoration: underline;

            &:hover {
                color: grey;
            }
        }
    }
}

.sequence-builder-container {
    .sequence-step-container {
        background: grey;
        border-radius: 5px;
        border: 2px solid gray;
    }

    .sequence-step-completed {
        background: #0084ff;
        border: 2px solid #0084ff;
    }

    .sequence-step-header {
        color: #fff;
        text-align: center;
        padding: 5px;
        font-size: 16px;
    }

    .sequence-step-body {
        background: #fff;
        padding: 10px;
    }

    .sequence-step-edit-flex {
        display: flex;
    }

    .sequence-step-react {
        flex: 1 1 0;
        padding-right: 50px;
    }

    .sequence-step-segment-edit {
        text-align: right
    }

    .sequence-step-label {
        font-weight: 600;
        margin-top: 10px;
    }

    .sequence-step-delay-label {
        margin-top: 10px;
    }

    .sequence-step-segment-selected {
        padding: 10px;
        display: flex;
        justify-content : space-between;

        .sequence-step-segment-text {
            align-self: center;

            a {
                color: black;
                text-decoration: underline;

                &:hover {
                    color: grey;
                }
            }

        }
    }

    #sequenceBuilder > div {
        margin: 50px 0px;
    }
}

.sequence-step-add-button {
    text-align: center;

    svg {
        cursor: pointer;
        width: 50px;
        height: 50px;
        color: #0084ff;
    }
}

.delayPickerLabel {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.delayPickerOverlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.delayPickerContent {
    background-color: white;
    border-radius: 10px;
    box-shadow: hsl(206, 22%, calc(7% / 35%) * 1%) 0px 10px 38px -10px, hsl(206, 22%, calc(7% / 20%) * 1%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    max-width: 450px;
    max-height: 85vh;
    padding: 15px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

    .error {
        align-self: center;
        margin-right: 10px;
    }

    fieldset,
    input {
        all: unset;
    }

    fieldset {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;

        label {
            font-size: 15px;
            color: black;
            width: 90px;
            text-align: right;
        }
        input {
            width: 100%;
            flex: 1;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            padding: 0 10px;
            font-size: 15px;
            line-height: 1;
            color: black;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
            height: 30px;
        }
    }

    .delayPickerCloseButton {
        font-family: inherit;
        border-radius: 100%;
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #0084ff;
        position: absolute;
        top: 10px;
        right: 10px;

        :hover {
            background-color: #99caf7;
            border-radius: 100%;
            padding: 2px;
        }
        :focus {
            box-shadow: 0 0 0 2px #0084ff;
        }
    }
}
.delayPickerContent:focus {
    outline: none;
}
.delayPickerTitle {
    margin: 0;
    font-weight: 600;
    color: black;
    font-size: 15px;
}
.delayPickerDescription {
    margin: 5px 0 20px;
    color: #65636d;
    font-size: 15px;
    line-height: 1.5;
}
.delayPickerFooter {
    display: flex;
    margin-top: 25px;
    justify-content: flex-end;
}


@keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
}

.sequence-preview-container {
    box-sizing: border-box;
    display: inline-block;
}

//preview popover

/* reset */
.PopoverClose {
    all: unset;
}

.PopoverContent {
    border-radius: 4px;
    padding: 20px;
    width: 230px;
    background-color: white;
    box-shadow: 0px 10px 38px -10px, 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
}
.PopoverContent:focus {
    box-shadow: 0px 10px 38px -10px, 0px 10px 20px -15px;
}
.PopoverContent[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
}
.PopoverContent[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
}
.PopoverContent[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
}
.PopoverContent[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
}

.PopoverArrow {
    fill: white;
}

.PopoverClose {
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    float: right;
    top: 5px;
    right: 5px;
}
.PopoverClose:hover {
    cursor: pointer;
}

.PopoverText {
    margin: 0;
    color: var(--mauve-12);
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

// scroll area

@import '@radix-ui/colors/black-alpha.css';

.ScrollAreaRoot {
    width: 200px;
    overflow: hidden;
    background-color: white;
    --scrollbar-size: 10px;
}

.ScrollAreaViewport {
    width: 100%;
    max-height: 225px;
    border-radius: inherit;
}

.ScrollAreaScrollbar {
    display: flex;
    /* ensures no selection */
    user-select: none;
    /* disable browser handling of all panning and zooming gestures on touch devices */
    touch-action: none;
    padding: 2px;
    transition: background 160ms ease-out;
    margin-top: 20px;
    background: var(--black-a5);
    border-radius: var(--scrollbar-size);
}
.ScrollAreaScrollbar:hover {
    background: var(--black-a6);
}
.ScrollAreaScrollbar[data-orientation='vertical'] {
    width: var(--scrollbar-size);
}
.ScrollAreaScrollbar[data-orientation='horizontal'] {
    flex-direction: column;
    height: var(--scrollbar-size);
}

.ScrollAreaThumb {
    flex: 1;
    background: var(--black-a9);
    border-radius: var(--scrollbar-size);
    position: relative;
}
/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.ScrollAreaThumb::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
}

.ScrollAreaCorner {
    background: var(--black-a8);
}

.sequence-status {
    text-transform: lowercase;
}
.sequence-status::first-letter {
    text-transform: uppercase;
}
