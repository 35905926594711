.page-onboarding {
  color: #313131;
  font-size: 24px;
  vertical-align: top;

  a.plain {
    color: inherit;
    text-decoration: underline;
  }

  ul.steps {
    padding: 0;
    margin: 20px 0 20px 0;
    list-style: none;
    font-size: 14px;

    & > li {
      line-height: 45px;
      padding: 15px 20px;
      border-bottom: 1px solid rgba(0,0,0,0.09);

      &:first-child {
        border-top: 1px solid rgba(0,0,0,0.09);
      }

      input[type=text] {
        margin-bottom: 20px;
      }

      .clippy {
        line-height: 1.5;
      }
    }
  }

  ul.ticks {
    padding: 0;
    list-style: none;
    font-size: 14px;
    margin: 0 0 10px 0;

    li {
      line-height: 26px;


      i {
        font-family: 'FontAwesome';
        margin-right: 4px;
      }
    }
  }

  h1 {
    &.title {
      font-size: 36px;
      color: #FF7573;
      font-weight: 500;
    }

    &.step {
      font-size: 24px;
      color: #313131;
      letter-spacing: 0;
      line-height: 36px;
    }
  }

  .card-no-padding {
    background-color: white;
    padding: 30px 0;

    .pad {
      margin-left: 20px;
    }
  }

  .btn {
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    min-width: 80px;

    &.btn-success {
      background: #FF7676;
      border: none;
      color: white;
    }

    &.btn-outline-secondary {
      background: transparent;
      color: #000000;
      border-color: #2CABE3;
    }
  }

  .card {
    padding: 50px;
    min-height: 502px;
  }

  .extra-information {
    display: flex;
    align-items: center;
  }

  &.step-welcome {
    .video {
      width: 100%;
      display: inline-block;
      vertical-align: middle;
    }
  }


  &.step-carousel {
    .btn.start {
      display: inline-block;
      margin: 20px 0;
    }

    .try-me {
      margin-left: auto;
      width: 300px;
    }

    .concerns {
      font-size: 14px;
      color: #313131;
    }
  }


  &.step-faq {
    .btn.btn-success {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &.step-intro {
    .squash-center {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  &.step-create-integration {
    .card {
      margin-bottom: 0;
    }

    .concerns {
      margin-top: 16px;
      font-size: 14px;
      line-height: 21px;
    }

    .alert.alert-danger {
      font-size: 16px;
    }

    ul li p {
      line-height: 21px;
      margin-bottom: 0;
    }

    ul li strong {
      font-weight: 500;
    }
  }

  .btn__facebook-btn {
    display: inline-block;
    line-height: 1.5;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 7px;
    background-color: transparent;
    border: 1px dashed #67757c;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 500;
    color: #67757c;
    margin-left: 5px;
    margin-right: 5px;
  }

  img.img__help {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border: 1px solid lightgray;
  }

  form p {
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 26px;
  }


    .form-group.has-error {
        .help-block {
            display: block;
        }
    }
}
