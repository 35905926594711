&__modalButtons {
	text-align: center;
}

&__modalContainer {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(40, 44, 55, 0.78);
	display: none;
	z-index: 999;
}

&__modal {
	position: absolute;
	width: 50%;
	top: 20%;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	background-color: white;
	border: 1px black solid;
	padding: 50px;
	border-radius: 5px;

	h3 {
		text-align: center;
	}

	h4 {
		color: red;
		text-align: center;
	}
}

&__modalSelector {
	.form-group {
		.select2-container {
			width: 100% !important;
		}
	}
}
