
table.vertical-align-middle.table > tbody > tr > td {
	vertical-align: middle;
}

.table > tbody > tr > td, .table > thead > tr > th {
	font-size:1.2em;
	padding: 4px 8px;
	vertical-align: middle;
}

.table-actions {
	width: 150px;

	button:focus {
		outline: none;
	}
}

.panel {
  .panel-title {
  	font-size:14px;
  	padding:20px;
  	font-weight: 600;
  }
}

.pill {
	.pill-label:before {
		font-size: 8px;
		vertical-align: middle;
		font-weight: 600;

		color: #594C06;
		margin-left: 0px;
		margin-right:10px;
		text-transform: uppercase;
		padding: 2px 4px;
		border-radius: 3px;
	}
}

.info-pill {
	.pill-label:before {
	}
}

.warning-pill {
	.pill-label:before {
		background-color: $brand-danger;
		color:#fff;
	}
}

.label-message-tag-container {
	float: left;

	.label-message-tag {
		background-color: #FBE983;
		font-size: 8px;
		vertical-align: middle;
		font-weight: 600;
		color: #594C06;
		margin-left: 0px;
		margin-right: 10px;
		text-transform: uppercase;
		padding: 2px 4px;
		border-radius: 3px;
	}
}

.no-message {
	.pill-label:before {
		content: "no message";
	}
}
