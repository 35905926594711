
a.help-widget {
    i {
        font-size: 20px !important;
        line-height: 20px !important;
        background-color: white !important;
        border-radius: 50% !important;
        height: 20px !important;
        width: 20px !important;
        color: inherit !important;
    }
    transition: all .1s ease-in;
    opacity: 1;
    &--type {
        &-light {
            color: #fff !important;
            &:hover{
                opacity: 0.7;
            }
        }

        &-dark {
            color: $brand-primary !important;
            &:hover{
                color: lighten( $brand-primary, 10% );
            }
        }
    }
}
