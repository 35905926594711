.spinner {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner .6s linear infinite;
}

.spinner-inline {
  content: '';
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner .6s linear infinite;
}
