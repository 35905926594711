
$brand-primary: #0084ff;
$brand-secondary: #181B26;
$brand-tertiary: #74808F;

$brand-success: #24B685;
$brand-danger: #EA5A5A;
$brand-warning: #FFAD46;
$brand-teams: #4852ba;
$brand-slack: #4A154B;
$brand-flip: #145af5;

$link-color: $brand-tertiary;
$sidebar-hover-color: #fff;

$nav-link-padding: 4px 15px;
$nav-link-hover-bg: $brand-secondary;

$navbar-default-bg: #fff;

$navbar-height: 80px;

$state-danger-text: $brand-danger;
