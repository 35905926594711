.page-message-uploads, .page-segment-management {
  .file-drop-area {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 450px;
    max-width: 100%;
    padding: 25px;
    border: 1px dashed rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    margin-bottom: 2rem;
    &.disabled {
      opacity:25%;
      cursor: not-allowed;
      .file-input {
        cursor:not-allowed;
      }
    }
    .fake-btn {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      background-color: rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 8px 15px;
      margin-right: 10px;
      margin-left: 10px;
      font-size: 12px;
    }
    .file-msg {
      font-size: small;
      font-weight: 300;
      line-height: 1.4;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-input, input[type=file] {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }
}
