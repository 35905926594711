.page-media-response {
  .form-group {
	margin: 0;

	.control-label {
	  margin-bottom: 5px;
	}
  }

  .select2 {
	width: 100% !important;
  }
}
