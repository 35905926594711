.fake-mobile {
  width:265px;
  height:540px;
  background:url('/public/img/tbp-phone.png');
  background-size:contain;
  background-repeat:no-repeat;
  padding-top:75px;
  box-sizing:border-box;
  display:inline-block;
  vertical-align: top;
  margin-right:50px;
  margin-bottom:40px;
  &__contents {
    border:1px solid $brand-primary;
    background:#fff;
    position:relative;
    margin:0 auto;
    width:223px;
    height:397px;
    box-sizing:border-box;
    padding-top:35px;
    .fake-mobile__intro-screen & {
      background:url('/public/img/cms-screen/get-started.png');
      background-size:cover;
      background-repeat:no-repeat;
    }

  }

  &__cover-photo {
    width:100%;
    height:65px;
    background-size:cover;
    background-position:center center;
  }
  &__profile-pic {
    width:40px;
    position:absolute;
    top:80px;
    left:50%;
    margin-left:-20px;
    height:40px;
    border-radius:50%;
  }
  &__top-title {
    text-align:center;
    padding-top:25px;
    font-size:10px;
  }
  &__top-title-super-top {
    font-size:6px;
    position:absolute;
    top:12px;
    opacity:0.6;
    font-weight: 600;
    left:0;
    right:0;
    text-align:center;
  }
  &__get-started-message {
    font-size:9px;
    white-space: pre-wrap;
    position:absolute;
    top:187px;
    left:30px;
    right:30px;
    height:50px;

  }
}
