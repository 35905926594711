.utmForm {
  width: 50%;
}

.utmEmphasis {
  font-weight: 600;
}

.utmButton {
  width: 200px !important;
}

.utmRemoveButton {
  margin-top: -20px;
}
