.page-data-export-response-data {
      #items li.item {
        padding: 10px;
        background-color: whitesmoke;
        margin-bottom: 5px;
        border-radius: 4px;
        cursor: ns-resize;
        font-weight: 600;
      }
      .checkbox {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      .checkbox label {
        color: #333 !important;
      }
      .checkbox .checkbox-material {
        margin-right: 5px;
      }
      .checkbox.indent {
        padding: 10px;
      }
      .checkbox input:disabled + span.checkbox-material span.check {
        background-color: lightgray;
      }

      .datepicker {
        display: inline-block;
        width: 150px;
      }

      &.dataset-controls {
        margin-bottom: 20px;
      }

      &.dataset-controls .datepickers {
        position: relative;
        float: right;
        max-width: 305px;
        min-width: 150px;
        width: 50%;
      }
      .datepicker .form-group {
        margin-top: 0;
      }
      .dataset-unique-users {
        display: none;
        text-align: right;
        font-size: 16px;
      }
      .dataset-unique-users > p > span {
        font-weight: 600;
      }
}
