@import 'bootstrap';
@import '../libraries/bootstrap-material-design/scss/bootstrap-material-design.scss';
@import '../libraries/bootstrap-material-design/scss/ripples';

@import 'previewer';
@import 'animation';
@import 'brand';

@import 'utility';

@import 'includes/empty-state';
@import 'includes/filter';
@import 'includes/navbar';
@import 'includes/left-menu';
@import 'includes/tables';
@import 'includes/help-widget';
@import 'includes/common';
@import 'includes/page-adminredistool';
@import 'includes/page-analytics';
@import 'includes/page-messenger-status';
@import 'includes/page-home';
@import 'includes/page-schedule';
@import 'includes/page-broadcasts';
@import 'includes/page-broadcasting-message-picker';
@import 'includes/page-data-export-response-data';
@import 'includes/page-api';
@import 'includes/page-messages';
@import 'includes/page-deeplink';
@import 'includes/page-segment-management';
@import 'includes/page-sequence-management';
@import 'includes/page-followup';
@import 'includes/page-user-engagement';
@import 'includes/page-message-engagement';
@import 'includes/page-customer-chat-plugin';
@import 'includes/page-signup';
@import 'includes/page-billing';
@import 'includes/page-userattributes';
@import 'includes/page-workplace-integration';
@import 'includes/page-teams-integration';
@import 'includes/page-ignored-messages';
@import 'includes/page-media-response';
@import 'includes/page-organization-authentication';
@import 'includes/file-upload-dropper';
@import 'includes/keyword-form';
@import 'includes/login-page';
@import 'includes/bootstrap-overrides';
@import 'includes/background-colors';
@import 'includes/keyword-form';
@import 'includes/nux';
@import 'includes/fake-mobile';
@import 'includes/beta';
@import 'includes/_utm-form.scss';
@import 'includes/datatables';
@import 'includes/clipboard';
@import 'includes/spinner';
@import 'message-builder';
@import 'analytics';
@import 'campaignbuilder';
@import 'sequence';
@import 'sequence-datepicker';
@import 'sequentialrandom-builder';
@import 'daypicker';
@import 'includes/organisations/bots';
@import 'includes/organisations/sso';
@import 'includes/page-connections';
@import 'includes/alert-dialog';

@media(max-width:750px) {
  .no-mobile {
    display:none;
  }
}

body,h1,h2,h3,h4,h5,p {
  font-weight: 400;
}

body .container .well p, body .container .jumbotron p, body .container-fluid .well p, body .container-fluid .jumbotron p {
  font-weight: 400;
}

.logo-circle {
    border-radius: 100%;
    width: 50px;
    height: 50px;
}

.trial-over {
  position:fixed;
  z-index:99999999999;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:url("/public/img/bg.jpg");
  color:#fff;
  text-align:center;
}

body .container-fluid p.bold {
  font-weight: 600;
}


.info-desc {
  margin-top:-30px;
  margin-bottom:30px;
  font-size: 14px;
  font-weight: 400;
  img {
    vertical-align: middle;
  }
  a {
    color: #333;
    border-bottom: 1px solid $brand-primary;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color:$brand-primary;
    }
  }
}


@media(max-width:1100px) {
  .reduced-view {
    display:none;
  }
}

.c {
  text-align: center;
}
td.constrained-width {
  overflow:hidden;
  max-width:300px;
  text-overflow:ellipsis;
  word-wrap:break-word;
}

.message-thread {
  .form-footer {
    float:right;
  }
}
.row-picture {
  display:inline-block;
}
.message-bubble {
  background:$brand-primary;
  padding:10px 20px;
  border-radius:10px;
  color:#fff;
  font-weight: 600;
  width:auto;
  display:inline-block;
  min-width:100px;

}
.message-date {
  text-align:left;
  font-weight: 600;
}
.platform-icon {
  position:relative;

  &:after {
    display:block;
    content: '';
    width:20px;
    height:20px;

    background-size:contain;
    position:absolute;
    bottom:0px;
    right:-5px;
  }

  &.messenger:after {
    background-image:url('/public/img/messenger.png');
  }

  &.workplace:after {
    background-image:url('/public/img/messenger-workplace-icon.png');
  }
}

a.quick-link {
  padding-bottom:3px;
  border-bottom:1px dashed $brand-primary;
  &:hover {
    text-decoration: none;
    color:$brand-primary;
    border-bottom:1px solid $brand-primary;
  }
}

.date-time-container {
  select {
    margin:0 2px;
  }
}
.date-picker-button {
  // color:#000;
  background:none;
  padding:0;
  margin:0;
  border:none;
  margin-left:10px;
  font-size:20px;
  cursor:pointer;
  &:hover {
    color:$brand-primary;
  }
}
.navbar-toggle {
  margin-top: 25px;
  margin-bottom:0;
}
.navbar .navbar-toggle .icon-bar {
  background:#000;
}

.form-control,
.form-group .form-control {
  transition: background 0.3s ease-out;
}
.form-footer {
  text-align:right;
}

.well {
  max-width:800px;
}

.titleHeader:hover, .categoryHeader:hover {
  cursor: pointer;
}

body {
  font-size:12px;
}
.float-left {
  float:left;
}
.float-right {
  float:right;
}
.form-group textarea.form-control {
  height:2.5em;
  overflow: hidden;
}

.container-fluid.no-sidebar {
  padding-top:50px;
}

.container-fluid.right {
  padding-top:30px;
}
.main {
  background-color: #f4f7fa;
  border-radius:$border-radius-base;
  border:1px solid $navbar-default-border;
  overflow: hidden;
}
h2 {
  margin-bottom:40px;
  margin-top:0;
  padding-top:0;
}

.stat-total-subs {
  background: no-repeat right top;
  background-size: 100% 100%;
  height: 600px;
}

.stat-subtitle {
  color: $brand-primary;
  font-weight: 600;
}

.btn, .input-group-btn .btn {
  text-transform: none;
}


.locked {
  &.material-icons {
    cursor: not-allowed;
    color: #000000;
    vertical-align: middle;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-locked {
  opacity: 40%;
  cursor: not-allowed;
  i {
    font-size: 16px;
  }
}

.btn.btn-xs {
  -webkit-font-smoothing: antialiased;
}

.btn.btn-plain {
  border: #ddd solid 1px;
  color: $brand-primary;
}

.btn.btn-qrcode {
  border: $brand-primary solid 2px;
  border-radius: 100%;
  color: $brand-primary;
  padding: 2px;
}

.new-keyword {
  background-color: rgba( $brand-success, .3 );
}

.missing-content {
  background-color: rgba( $brand-danger, .7 );
}

.column-info {
  font-size: 16px !important;
  margin-left: 5px;
}

.slide-up {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  transition-property: height, padding-top, padding-bottom;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
}

.cta-push-changes {
  background-color: blue;
  margin-left: -30px;
  margin-top: -30px;
  width: calc(100% + 60px);
  height: 86px;
  overflow: hidden;
  padding: 0;

  .cta {
    font-size: 14px;
    font-weight: 600;
    line-height: 52px;
    height: 100%;
    margin: 0;
    overflow: hidden;

    .material-icons {
      display: inline-block;
      vertical-align: middle;
      font-size: 35px;
      margin-right: 10px;
    }
  }
}

.alert {
  &.fixed-alert {
    margin-left:-30px;
    margin-top:-30px;
    width:calc(100% + 60px);
    padding-left:30px;
    padding-right:30px;
    @media(max-width:900px) {
      text-align:center;
      .btn {
        display:block;
      }
    }
  }

  &.alert-warning {
    color: black;

    &.push-alert {
      color:#5D3707;
      font-size:14px;
      font-weight: 600;
      i.material-icons {
        color: $brand-warning;
        background:#5D3707;
      }
    }

    i.material-icons {
      display:inline-block;
      vertical-align: middle;
      border-radius:50%;
      font-size:35px;
      height:44px;
      width:44px;
      text-align:center;
      line-height:40px;
      background:#5D3707;
      color: $brand-warning;
      margin-right:10px;
    }
  }
}

.avatar {
  width:40px;
  height:40px;
  display:inline-block;
  vertical-align:middle;
  border-radius:50%;
  margin-right:0.5em;
}

/* Temporary */
.todo {
  text-decoration: line-through;
  color: black;

  &:hover {
    color:transparent;
    &:before {
      content: 'Coming soon';
      position:absolute;
      white-space:nowrap;
      left:15px;
      display:block;
      top:4px;
      color:black;

    }
    background-color: transparent !important;
  }
}

.noscroll {
  overflow: hidden;
}

// These fixes added after npm upgraded a bunch of stuff and broke things, can be removed when jquery-emojipicker is gone, I hope
.newEmojiPicker .emojiPicker {
  display: block !important;

  .emoji-dialog-header div.emoji {
    width: 22px;
    height: 22px;
  }
}

.table-striped#message-list th, #userlist th, #broadcast-list th {
  color: $brand-primary;
  &:hover {
    text-decoration: underline;
  }

  a {
    color: inherit;
  }
}

#userlist th:after {
  display: none;
}

.actions-th {
  color:#333 !important;
  cursor: auto;
  &:hover {
    text-decoration: none !important;
  }
}

.ensure-md-errors-visible {
  .form-group.has-error {
    .help-block {
      display: block !important;
    }

    .form-control {
      background-image: linear-gradient(#EA5A5A, #EA5A5A), linear-gradient(#D2D2D2, #D2D2D2);
      border-color: #843435;
      background-size: 100% 2px, 100% 1px;
    }
  }

}

.has-error a {
  color: #EA5A5A;
  text-decoration: underline;
}

code {
  display: inline-block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.428571429;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-push-live {
  &.loading {
    padding-right: 4rem;
  }

  &:disabled {
    background-color: white !important;
  }
}

.ready-to-launch-on-dashboard {
  ul {
    list-style:none;
    padding-left:0;

    .material-icons {
      vertical-align: bottom;
      font-size: 18px !important;
      cursor: default;
    }
  }
}

.list-group .list-group-item .row-picture img {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
}

.clipboard {
    position: relative;

    .tooltip-text {
        display: none;
        position: absolute;
        top: -30px;
        left: -100%;
        z-index: 2;
        color: white;
        font-size: 11px;
        background-color: #192733;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
    }
}

strong {
    font-weight: 600;
}

th {
    font-weight: 600;
}
