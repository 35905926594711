.confirmRemoveFollowupDialog {
    background-color: white;
    box-shadow: rgba(14, 18, 22, 0.35) 0px 10px 38px -10px, rgba(14, 18, 22, 0.2) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 500px;
    max-height: 85vh;
    color: black;
    text-align: center;
    border-radius: 5px;
    z-index: 4;

    .oops, .loseSettings {
        width: 130px;
        padding: 7px 7px;
        margin-right: 5px;
        margin-left: 5px;
    }

    .cancelImage {
        width: 100%;
        border-radius: 5px 5px 0 0;
    }

    h1, .title {
        font-weight: 600;
        font-size: 1.5em;
        margin-top: 20px;
    }

    p {
        font-size: 1.5em;
    }
}

.confirmRemoveFollowupDialogOverlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    inset: 0px;
}
