.QuickReplies {
  display: block;
  z-index: 1000;
}

.showQuickReplies + .profilePic {
  position: relative !important;
  top: -300px;
}

.showQuickReplies > .QuickReplies {
  display: block;
}

.QuickReplies {
  position: relative;
  height: 70px;
  margin-bottom: 3px;
  padding-left: 50px;
  margin-top: 3px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  .QuickReply {
    border-radius: 20px;
    border: 1px solid $brand-primary;
    background: #fff;
    color: $brand-primary;
    display: inline-block;
    width: auto;
    padding: 5px 10px;
    font-size: 10px;
    margin: 0 5px;
    position: relative;
    white-space: nowrap;
    height: 30px;
    vertical-align: middle;
    .quickReplyInput {
      height: 20px;
      line-height: 10px;
      position: relative;
      top: -2px;
      display: inline-block;
      vertical-align: middle;
      border: none;
      width: 50px;
      background: transparent;
      margin-left: 20px;
    }

    .inputButtonSettings {
      cursor: pointer;
      display: inline-block;
      i {
        font-size: 16px;
        margin-right: 5px;
      }
      vertical-align: top;
    }
    * {
      vertical-align: top;
    }

    .emojiPickerIcon {
      width: 30px !important;
      height: 30px !important;
      top: 4px;
      left: 25px;

      .newEmojiIcon {
        padding-left: 2px;
        font-size: 18px;
      }
    }

    .newEmojiPicker {
      position: absolute;
    }
  }
  .addQuickReply {
    cursor: pointer;
    padding-right: 10px;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}

.QuickReply:hover {
  .quick-reply-sort {
    display: block;
  }
}

.quick-reply-sort {
  text-align: center;
  padding: 5px 0;
  display: none;

  &:hover {
    display: block;
  }

  .quick-reply-sort-left,
  .quick-reply-sort-right {
    width: 25px;
    display: inline-block;
  }
}

.navigationMode {
  .QuickReply {
    cursor: pointer;
  }

  .quickReplyInput {
    margin-left: unset !important;
    cursor: pointer;
    text-align: center !important;
  }
}