
.page-userengagement {
  .summary {

    &__table {
      vertical-align: top;
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      margin: 10px 0;
    }

    &__label {
      width: 100px;
    }

    &__label, &__value {
      display: inline-block;
      font-size: 14px;
    }
  }
}
