
.navbar.navbar-default {
	background-color: #fff;
	border-bottom:1px solid rgba(#000000, 0.15);
	padding-right:20px;
	.navbar-brand {
		transform: translateY(4px);
	}
	.circle {
		width:40px;
		height:40px;
		border-radius:50%;
		background-size:cover;
		margin-right:5px;
		display:inline-block;
		vertical-align: middle;
	}
	.navbar-nav > li > a {
		color: #777;
		font-weight: 600;
		line-height:$navbar-height;
		padding-top:0;
		padding-bottom:0;

		&:hover {
			color: $brand-primary;
		}
	}
	.navbar-nav > li.active {
		display:inline-block;
		padding-top:15px;
		& > a {
			padding-left:5px;
			padding-right:15px;
			background:none;
			color: $brand-primary;
			border:1px solid rgba($brand-primary, .13);
			border-radius:40px;
			line-height:45px;
			height:50px;
			vertical-align: middle;
			padding-top:0;
			font-weight:normal;
			font-size:1.1em;
			&[href]:hover {
				background:$brand-primary;
				color:#fff;
				border-color:$brand-primary;
			}
			&:not([href]) {
				cursor: default;

				.ripple-container {
					display: none;
				}
			}
		}
	}
	.navbar-nav > .active {
		> a, > a[href]:hover, > a[href]:active, > a[href]:focus {
			color: #555;
			background-color: #dde6f0;
		}
	}
}

.navbar-brand {
	padding:12px 25px;
	img {
		height: 30px;
		margin-top: 10px;
		vertical-align: middle;
	}
}
