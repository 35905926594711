
.page-messenger-status {
	margin: 0 auto;
	width: 800px;
	padding: 16px;
	padding-top:50px;

	font-size: 15px;

	h2 {
		margin-bottom: 0;
	}

	h3 {
		font-size: 17px;
		font-weight: 600;
	}

	.standout {
		color: red;

		a {
			color: inherit;
		}
	}

	a {
		color: blue;
		text-decoration: underline;
	}
}

