
.page-organization-authentication {
	.authentication {
		&__enableSSORow {
			text-align: center;

			h2 {
				font-weight: 600;
				padding-top: 25px;
			}

			P {
				font-weight: 600;
				font-size: 16px;
			}
		}
	}

	.help-block {
		display: block;
	}
}
