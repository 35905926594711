.panel-beta {
	background:$brand-primary;
	color:#fff;
	padding:20px;
	padding-left:180px;
	position:relative;
	margin-bottom:40px;
	margin-top:0px;
	.beta-image {
		height:140px;
		width:140px;
		position:absolute;
		left:20px;
		top:-20px;
		background-size:contain;
	}
	a {
		color:#fff;
		border-bottom:1px dashed #fff;
		&:hover {
			border-bottom:1px solid #fff;
		}
	}
	.info-desc {
		padding-bottom:0px;
		margin-bottom:0px;

	}
}
@media(max-width:900px) {
	.panel-beta {
		padding-left:80px;
		margin-bottom:20px;
		.beta-image {
			left:20px;
			top:20px;
			display:inline-block;
			width:40px;
			height:40px;
		}
	}
}
