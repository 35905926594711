.brand-three-dots {
    line-height: 4em;

    .dot {
        display: inline-block;
        margin-left: 8px;
        margin-right: 8px;
        border-radius: 100%;
        width: 15px;
        height: 15px;
        animation-duration: 3000ms;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-name: brand-three-dots-bounce;
    }

    .dot-1 {
        background-color: rgb(0, 132, 255);
        animation-delay: 0;
    }

    .dot-2 {
        background-color: rgb(55, 62, 76);
        animation-delay: 250ms;
    }

    .dot-3 {
        background-color: rgb(85, 88, 175);
        animation-delay: 500ms;
    }
}

@keyframes brand-three-dots-bounce {
    0% {
        transform: translateY(0);
    }

    3% {
        transform: translateY(1px);
    }

    7% {
        transform: translateY(-13px);
    }

    13% {
        transform: translateY(4px);
    }

    14% {
        transform: translateY(0);
    }
}
