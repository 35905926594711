.nav-filter {
	background:none;
	// border:1px solid $modal-content-border-color;
	// border-radius:$border-radius-base $border-radius-base 0 0;
	border-bottom:0;
	padding:0 10px;
	position:relative;
	min-height:40px;

	.pagination {
		display:inline-block;
		width:auto;
		vertical-align:middle;
		margin-right:2em;
	}

	.search {
		position:absolute;
		right:5px;
		top:0px;
		display:inline-block;
		vertical-align:middle;
		width:80%;
		max-width:300px;
		.form-group {
			margin:0;padding:0;
			position:relative;
		}
		.search-clear {
			position:absolute;
			right:0;
			top:5px;
		}
	}
}

@media(max-width:1100px) {
	.nav-filter .search {
		position:static;
	}
}
