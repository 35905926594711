// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.panel {
  border-radius: 2px;
  border: 0;

  @include variations(unquote(".panel"), unquote(" > .panel-heading"), background-color, $grey-200);
  @include shadow-z-1;
}


[class*="panel-"] > .panel-heading {
  color: $mdb-text-color-light;
  border: 0;
}
.panel-default, .panel:not([class*="panel-"]) {
  > .panel-heading {
    color: $mdb-text-color-primary;
  }
}
.panel-footer {
  background-color: $grey-200;
}
