
.page-billing {
  padding-top: 50px;

  .checkbox {
    margin-top: 2em;
  }

  thead tr th {
    cursor: pointer;
    white-space: nowrap;
  }

  h2 {
    margin: 0;
  }
}
