.page-broadcasting-message-picker {
    .message-picker {
        margin-bottom: 23px;
    }
    .message-picker .form-group {
        margin: 0;
    }
    .message-picker-loading {
        margin-top: 36px;
    }

    .message-picker-issues, .message-picker-loading {
        display: none;
    }

    .message-picker-issues .label {
        margin-bottom: 5px;
        text-align: left;
        display: block;
        font-size: 12px;
        text-wrap: wrap;
    }

    .message-picker-issues .label strong {
        margin-right: 10px;
    }
}
