/* MessageBuilder */

@import 'message-builder-upload';

$hover-color: rgba(0, 132, 255, 0.15);

@import 'includes/quick-replies';

.error {
  color: $brand-danger;
}

.urlError {
    color: $brand-danger;
    left: 75px;
    top: 50px;
    position: absolute;
}

.noCategory {
  color: #aaa;
}

.categoryVisible {
  display: flex;
  align-items: baseline;
}

.categoryContainer {
  flex: 4;
}

.titleContainer {
  flex: 4;
  margin-right: 16px;
}

.well {
  max-width: none !important;
}

.categoryButtonContainer {
  flex: 1;
  text-align: center;
  vertical-align: top;

  button {
    border-radius: 5px;
    background-color: #fff;
    color: #0084ff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    white-space: nowrap;

    &:hover {
      border: 1px solid #0084ff;
    }
  }
}

.newCategoryContainer {
  width: 54%;
  display: inline-block;

  .createName {
    width: 90%;
  }

  .createButton {
    position: relative;
    top: -90px;
    left: 0;
  }
}

.categoryHide {
  display: none;
}


.material-icons {
  cursor: pointer;
}

.reactSelectContainer {
  line-height: 1em;
  font-size: 1.25rem;
}

.cancelModal {
  background-color: rgba(0, 132, 255, 0.8);
  color: #FFF;
  border-radius: 0;
  display: block;
  min-height: 1000px;
  left: 0;
  overflow: auto;
  padding: 2px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11000;

  .cancelDialogue {
    background-color: #fff;
    position: absolute;
    top: -500px;
    left: 37%;
    width: 26%;
    min-height: 297px;
    color: black;
    text-align: center;
    border-radius: 5px;
    transform: translateY(-25%);
    transition: transform 0.5s;

    .oops, .loseSettings {
      width: 130px;
      padding: 7px 0;
      margin-right: 5px;
      margin-left: 5px;
    }

    .cancelImage {
      width: 100%;
      border-radius: 5px 5px 0 0;
    }

    .cancelQuestion {
      font-weight: 600;
      font-size: 1.5em;
      margin-top: 20px;
    }

    .cancelCopy {
      font-size: 1.5em;
    }

  }
}

.startDialogue {

  margin-top: 25px;
  position: relative;
  height: 96px;

  .handPointerBackground {
    position: absolute;
    background-color: #4B4E58;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .handPointer {
    position: absolute;
    top: 12px;
    left: 13px;
  }

  .modalText {
    position: absolute;
    top: 13px;
    left: 75px;
    font-size: 1.5em;

    &.max-2-or-3-lines {
      max-height: 50px;
      overflow: hidden;

      /* -webkit-box stuff works on everything except IE 11, max-height as a backup */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media only screen and (max-width: 500px) {
        max-height: 60px;
        -webkit-line-clamp: 3;
      }
    }
  }

  .labelName {
    font-weight: 600;
  }

  .dots {
    width: 50px;
    height: 44px;
    border-left: 2px dashed #fff;
    position: absolute;
    top: 50px;
    left: 25px;
  }

}

.dynamicDots {
  width: 50px;
  height: 120px;
  border-left: 2px dashed #fff;
  position: absolute;
  top: 50px;
  left: 25px;
}

.dynamicDots__additionalOptions {
  width: 50px;
  height: 139px;
  border-left: 2px dashed #fff;
  position: absolute;
  top: 5px;
  left: 25px;
}

.startDialogue.contentFeed__initial {
  height: 45px;
}

.startDialogue.contentFeed {
  height: 45px;
  margin-top: 0;
}

.chooseUrl {
  position: relative;
  height: 96px;

  .linkIconBackground {
    position: absolute;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .linkIcon {
    position: absolute;
    top: 14px;
    left: 13px;
    color: #0084FF;
  }

  .modalText {
    position: absolute;
    top: 0;
    line-height: 2.5em;
    left: 75px;
    font-size: 1.5em;
    width: 80%;
    border-bottom: 1px solid #fff;
    cursor: pointer;

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 0.7em;
      color: #D8D8D8;
      width: 500px;
    }

    .inputBox {
      background-color: transparent;
      border: none;
      width: 80%;
    }
  }
}

.chooseOptIn {
  position: relative;
  height: 96px;

  .switchIconBackground {
    position: absolute;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .switchIcon {
    position: absolute;
    top: 13px;
    left: 13px;
    color: #0084FF;
  }

  .modalText {
    position: absolute;
    top: 7px;
    left: 75px;
    font-size: 1em;
    width: 80%;
    cursor: pointer;

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 0.7em;
      color: #D8D8D8;
      width: 500px;

      span {
        font-weight: 600;
      }
    }

    .downArrow {
      position: absolute;
      top: -5px;
      left: 98%;
      font-size: 2em;
      font-weight: 200;
    }
  }

  .modalMenu {
    position: absolute;
    top: 14px;
    left: 10%;
    background-color: #fff;
    z-index: 10000;
    width: 236px;
    padding: 10px 20px;

    ul {
      padding-left: 0;

      li {
        color: #000;
        list-style: none;
        padding: 5px 0;
        cursor: pointer;
        font-size: 1.2em;

        &:hover {
          color: #0084FF;
        }
      }
    }
  }

  .dots {
    width: 50px;
    height: 44px;
    border-left: 2px dashed #fff;
    position: absolute;
    top: 50px;
    left: 25px;
  }

}

.choosePopulate {
  position: relative;
  min-height: 145px;

  .modalText {
    position: absolute;
    top: 0;
    left: 75px;
    width: 80%;
    cursor: pointer;
    clear: both;

    .modalEmphasis {
      font-weight: 600;
    }

    .modalOptions {
      line-height: 2.5em !important;
      font-size: 1.5em !important;
    }

    .modalHelp {
      font-size: 1em !important;
      line-height: 1em !important;
    }

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 0.7em;
      color: #D8D8D8;
      width: 500px;
    }

    .downArrow {
      position: absolute;
      top: -5px;
      left: 98%;
      font-size: 2em;
      font-weight: 200;
    }

    .choosePopulate__checkbox {
      margin: 0 10px 0 5px;
      font-size: 2em;
      height: 15px;
    }

    .optionHelp {
      position: absolute;
      top: 5px;
      left: 105%;
      background-color: #4B4E58;
      color: #fff;
      font-size: 0.65em;
      line-height: 1.3em;
      width: 170px;
      padding: 8px 12px;
      border-radius: 5px;
    }

    .modalError {
      position: absolute;
      top: 5px;
      left: 95%;
      background-color: red;
      border: 1px solid white;
      min-height: 100px;
      color: #fff;
      font-size: 0.65em;
      line-height: 1.3em;
      width: 170px;
      padding: 8px 12px;
      border-radius: 5px;
    }
  }

}

.finalCard {
  &__title {
    color: initial;
  }

  &__subtitle {
    color: initial;
  }
}

.rssCardButton {
  color: #0084ff;
}

.defaultButtonsFinalCard {
  display: flex;

  &__LHS {
    flex: 1;
  }

  &__RHS {
    flex: 1;
  }
}

.chooseMessage {
  position: relative;
  height: 96px;

  .bubbleIconBackground {
    position: absolute;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .bubbleIcon {
    position: absolute;
    top: 14px;
    left: 13px;
    color: #0084FF;
  }

  .modalText {
    position: absolute;
    top: 0;
    left: 75px;
    font-size: 1.5em;
    width: 80%;
    border-bottom: 1px solid #fff;
    cursor: pointer;

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 0.7em;
      color: #D8D8D8;
      width: 500px;
    }

    .inputBox {
      background-color: transparent;
      border: none;
      width: 80%;
    }

    .downArrow {
      position: absolute;
      top: -5px;
      left: 98%;
      font-size: 2em;
      font-weight: 200;
    }
  }

  .modalMenu {
    position: absolute;
    top: 58px;
    left: 10%;
    background-color: #fff;
    z-index: 10000;
    width: 400px;
    padding: 10px 20px;

    ul {
      padding-left: 0;

      li {
        color: #000;
        list-style: none;
        padding: 5px 0;
        cursor: pointer;
        font-size: 1.2em;

        &:hover {
          color: #0084FF;
        }
      }
    }
  }

  .dots {
    width: 50px;
    height: 44px;
    border-left: 2px dashed #fff;
    position: absolute;
    top: 50px;
    left: 25px;
  }
}

.chooseTag {
  position: relative;
  height: 96px;

  .tagIconBackground {
    position: absolute;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .tagIcon {
    position: absolute;
    top: 14px;
    left: 13px;
    color: #0084FF;
  }

  .modalText {
    position: absolute;
    top: 0;
    line-height: 2.5em;
    left: 75px;
    font-size: 1.5em;
    width: 80%;
    border-bottom: 1px solid #fff;
    cursor: pointer;

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 0.7em;
      color: #D8D8D8;
      width: 500px;

      &.postbackSuper {
        line-height: 1em;
      }
    }

    .downArrow {
      position: absolute;
      top: -5px;
      left: 98%;
      font-size: 2em;
      font-weight: 200;
    }

    .dots {
      width: 50px;
      height: 44px;
      border-left: 2px dashed #fff;
      position: absolute;
      top: 50px;
      left: 25px;
    }

    .inputBox {
      background-color: transparent;
      border: none;
      width: 80%;
    }

    .tagHelp {
      position: absolute;
      top: 5px;
      left: 105%;
      background-color: #4B4E58;
      color: #fff;
      font-size: 1em;
      line-height: 1.3em;
      width: 170px;
      padding: 8px 12px;
      border-radius: 5px;
    }
  }

  .modalMenu {
    position: absolute;
    top: 42px;
    left: 8%;
    background-color: #fff;
    z-index: 10000;
    width: 236px;
    padding: 10px 20px;

    ul {
      margin-bottom: 0;
      padding-left: 0;

      li {
        color: #000;
        list-style: none;
        padding: 5px 0;
        cursor: pointer;
        font-size: 1.2em;

        &:hover {
          color: #0084FF;
        }
      }
    }
  }

  .dots {
    width: 50px;
    height: 44px;
    border-left: 2px dashed #fff;
    position: absolute;
    top: 50px;
    left: 25px;
  }

}

.enterCustomPayload {
  position: relative;
  height: 96px;

  .customPayloadIconBackground {
    position: absolute;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .customPayloadIcon {
    position: absolute;
    top: 14px;
    left: 13px;
    color: #0084FF;
  }

  .modalText {
    position: absolute;
    top: 0;
    line-height: 2.5em;
    left: 75px;
    font-size: 1.5em;
    width: 80%;
    border-bottom: 1px solid #fff;
    cursor: pointer;

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 0.7em;
      color: #D8D8D8;
      width: 500px;
    }

    .downArrow {
      position: absolute;
      top: -5px;
      left: 98%;
      font-size: 2em;
      font-weight: 200;
    }

    .dots {
      width: 50px;
      height: 44px;
      border-left: 2px dashed #fff;
      position: absolute;
      top: 50px;
      left: 25px;
    }

    .inputBox {
      background-color: transparent;
      border: none;
      width: 80%;
    }

    .inputBox::-webkit-outer-spin-button,
    .inputBox::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .tagHelp {
      position: absolute;
      top: 5px;
      left: 105%;
      background-color: #4B4E58;
      color: #fff;
      font-size: 1em;
      line-height: 1.3em;
      width: 170px;
      padding: 8px 12px;
      border-radius: 5px;
    }
  }

  .modalMenu {
    position: absolute;
    top: 42px;
    left: 8%;
    background-color: #fff;
    z-index: 10000;
    width: 236px;
    padding: 10px 20px;

    ul {
      margin-bottom: 0;
      padding-left: 0;

      li {
        color: #000;
        list-style: none;
        padding: 5px 0;
        cursor: pointer;
        font-size: 1.2em;

        &:hover {
          color: #0084FF;
        }
      }
    }
  }

  .dots {
    width: 50px;
    height: 44px;
    border-left: 2px dashed #fff;
    position: absolute;
    top: 50px;
    left: 25px;
  }

}

.chooseBucket {
  position: relative;
  height: 96px;

  .bucketIconBackground {
    position: absolute;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .bucketIcon {
    position: absolute;
    top: 12px;
    left: 13px;
    color: #0084FF;
  }

  .modalText {
    position: absolute;
    top: 7px;
    left: 75px;
    font-size: 1em;
    width: 80%;
    cursor: pointer;

    .tagHelp {
      position: absolute;
      top: 5px;
      left: 105%;
      background-color: #4B4E58;
      color: #fff;
      font-size: 1em;
      line-height: 1.3em;
      width: 170px;
      padding: 8px 12px;
      border-radius: 5px;
    }

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 1em;
      color: #D8D8D8;
      width: 500px;
    }

    .inputBox {
      background-color: transparent;
      border: none;
      width: 80%;
    }

    .downArrow {
      position: absolute;
      top: -5px;
      left: 98%;
      font-size: 2em;
      font-weight: 200;
    }

    .bucketHelp {
      position: absolute;
      top: 0;
      left: 105%;
      background-color: #4B4E58;
      color: #fff;
      line-height: 1.3em;
      width: 170px;
      padding: 8px 12px;
      border-radius: 5px;
    }
  }

  .dots {
    width: 50px;
    height: 44px;
    border-left: 2px dashed #fff;
    position: absolute;
    top: 50px;
    left: 25px;
  }

  .modalMenu {
    position: absolute;
    top: 58px;
    left: 10%;
    background-color: #fff;
    z-index: 10000;
    width: 400px;
    padding: 10px 20px;

    ul {
      padding-left: 0;

      li {
        color: #000;
        list-style: none;
        padding: 5px 0;
        cursor: pointer;
        font-size: 1.2em;

        &:hover {
          color: #0084FF;
        }
      }
    }
  }

}

.numberFormatField {
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  color: #222;
  width: 75px;
  border: 1px solid #222;
}

.regexField {
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: #222;
  width: 100%;
  border: 1px solid #222;
}

.saveRemove {
  button {
    margin-right: 10px !important;
  }
}

.modalError {
  min-height: 48px;
  background-color: #EA5A5A;
  width: 80%;
  margin-left: 10%;
  padding: 20px;
  text-align: left;
  font-size: 1.3em;
  line-height: 1.5em;
  margin-top: -30px;
  margin-bottom: 20px;

  .modalEmphasis {
    font-weight: 600;
  }

  .tagConfirm {
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }

}

.modalEmphasis {
  font-weight: 600;
}

.chooseAction {
  position: relative;
  height: 96px;

  .plusIconBackground {
    position: absolute;
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .plusIcon {
    position: absolute;
    top: 14px;
    left: 13px;
    color: #0084FF;
  }

  .modalText {
    position: absolute;
    top: 7px;
    line-height: 2.5em;
    left: 75px;
    font-size: 1.5em;
    width: 80%;
    cursor: pointer;

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 0.7em;
      color: #D8D8D8;
      width: 500px;
    }
  }

  .modalTextWebview {
    position: absolute;
    top: 7px;
    line-height: 2.5em;
    left: 75px;
    font-size: 1.5em;
    width: 80%;
    cursor: pointer;
    border-bottom: 1px solid #fff;

    .textSuperscript {
      position: absolute;
      top: -25px;
      font-size: 0.7em;
      color: #D8D8D8;
      width: 500px;
    }
  }

  .dots {
    width: 50px;
    height: 44px;
    border-left: 2px dashed #fff;
    position: absolute;
    top: 50px;
    left: 25px;
  }

  .downArrow {
    position: absolute;
    top: -5px;
    left: 98%;
    font-size: 2em;
    font-weight: 200;
  }

  .modalMenu {
    position: absolute;
    top: 14px;
    left: 10%;
    background-color: #fff;
    z-index: 10000;
    width: 50%;
    padding: 10px 20px;

    ul {
      padding-left: 0;

      li {
        color: #000;
        list-style: none;
        padding: 5px 0;
        cursor: pointer;
        font-size: 1.2em;

        &:hover {
          color: #0084FF;
        }
      }
    }
  }

}

.messageBuilderWrapper {
  border: 1px #ccc solid;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  width: 305px;
  position: relative;

  &.navigationMode .messageHeader {
    background: #FF7B00;
  }
}

.messageBuilderWrapper .messageHeader {
  background: #0084ff;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.44);
  color: #fff;
  padding: 9px 7px 8px 13%;
  margin-bottom: 8px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 3;

  .botName {
    font-size: 14px;
  }

  .toggleMode {
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    padding-right: 20px;
    text-align: right;

    .material-icons {
      font-size: 14px;
      vertical-align: text-top;
      margin-left: 3px;
    }

    .toggleModal {
      color: black;
      font-size: 16px;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0px 0px 50px black;

      .btn {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 11px;
      }

      .btn:last-of-type {
        margin-top: 0;
        margin-bottom: 0;
      }
    }


  }

  .activeState {
    font-size: 10px;
  }
}

.MessageBuilder {
  background: #fff;
  height: 530px;
  overflow: auto;
  width: 300px;
}

.MessageBuilder .TextMessageContainer, .MessageBuilder .CarouselContainer, .ImageMessageContainer, .AudioMessageContainer, .VideoMessageContainer, .DelayMessageContainer, .QuestionMessageContainer, .MessageBuilder .MediaMessageBuilder, .MessageBuilder .DelayMessageBuilder, .GroupMessageContainer {
  position: relative;
}

.MessageBuilder .SortControls {
  position: absolute;
  text-align: center;
  width: 50px;
  height: 85%;
  z-index: 2;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  .material-icons {
    display: block;
    cursor: pointer;
  }
}

.MessageBuilder .CardControls {
  border-radius: 12px;
  background: rgba(255,255,255,0.5);
  height: 24px;
  overflow: hidden;
  left: 5px;
  position: absolute;
  top: 6px;
  font-size: 23px;

  .material-icons {
    display: inline-block;
    cursor: pointer;
  }
}

.MessageBuilder .Message {
  background: #eaeaea;
  border-radius: 18px;
  clear: both;
  float: none;
  margin: 0 8% 3px 18%; /* This is important */
  overflow: hidden;
  position: relative;
}

.Message .removePart {
  border-radius: 12px;
  background: rgba(255,255,255,0.5);
  height: 24px;
  overflow: hidden;
  right: 5px;
  position: absolute;
  top: 5px;
  width: 24px;

  .cardFramework__remove {
    position: relative;
    top: -5px;
  }
}

.QuickreplyMessageContainer .removePart {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.5);
  height: 24px;
  overflow: hidden;
  left: 25px;
  position: absolute;
  top: 2px;
  width: 24px;
}

.MessageBuilder .floatClear {
  clear: both;
}

.MessageBuilder .messageCluster {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}
.MessageBuilder .messageCluster .profilePic {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  background-size: cover;
  bottom: 3px;
  border-radius: 30px;
  height: 30px;
  left: 2.5%;
  position: absolute;
  width: 30px;
}

.MessageBuilderNoParts {
  padding: 20px;
  border: 2px dashed #eee;
  margin:20px;
  border-radius: 20px;

  &__label {
    font-weight: 600;
  }

  &--large {
    height: 124px;
  }
}

.Message.MessageSelector {
  background: #fff;
  border-radius: 5px;
  border: 1px solid rgba(#000, 0.2);
  padding: 10px;
  display: inline-block;
  margin-left: 20px;
  h2 {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .partList {
    position: relative;

    .menuContainer {
      position: absolute;
      z-index: 999;
      background-color: white;
      padding: 6px 10px 0px 10px;
      left: -11px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);

      ul {
        white-space: nowrap;

        li {
          display: inline-block;
          margin-right: 20px;
        }
      }
    }
  }
}

.MessageVariantPicker {
  position: absolute;
  left: 40px;
  right: 40px;
  margin-top: 14px;
}

.MessageSelector ul {
  list-style: none;
  margin: 0;
  padding: 0;

  .title {
    display: inline-block;
    padding-left: 10px;
  }
}

.MessageSelector ul li.selectorDisabled {
  opacity: 0.5;
}

.MessageSelector ul li .is-beta:after {
  content: '\3b2';
  font-weight: 600;
  position: relative;
  color: #0084ff;
  margin-left: 10px;
}

.MessageSelector ul li div {
  height:40px;
  width:40px;
  margin-bottom:5px;
  display:inline-block;
  background:#333;
  font-size:12px;
  color:#fff;
  border-radius:20px;
  padding: 0;
  text-align:center;
  line-height:40px;
  cursor: pointer;

  .openai-logo {
    width: 24px;
    height: 24px;
  }

  .openai-logo-black {
      display: none;
  }

  &:hover {
    background:#ccc;
    color:#000;

    .openai-logo {
        display: none;
    }

    .openai-logo-black {
        display: inherit;
    }
  }
  .material-icons {
    vertical-align: middle;
  }
}

/* MediaMessage */
.MessageBuilder .MediaMessageBuilder {
  border: 1px #eaeaea solid;
  min-height: 100px;
  overflow: hidden;
  padding: 0; /* must override .Message */
  position: relative;
  width: 222px;
}

.MediaMessageBuilder {
  .messageImage {
    max-width: 100%;
    position: relative;
  }

  .messageVideo {
    position: relative;
  }
}

.placeholderDecoration {
  cursor: pointer;
  transition: background-color 0.25s ease;
  &:hover {
    background-color: $hover-color !important;
  }
}

.moveCardDecoration, .removeDecoration {
  cursor: pointer;
  transition: all 0.25s ease;
  transition-property: background-color, color;
  &:hover {
    background-color: rgb(51, 51, 51) !important;
    color: rgba(255,255,255,0.5) !important;
  }
}

.MediaMessageBuilder .filePlaceholder {
  padding: 60px;
  text-align: center;
}

.MediaMessageBuilder .fileChanger {
  background: rgba(255,255,255,0.5);
  bottom: 0;
  left: 0;
  padding: 10px 0;
  height: 50px;
  position: absolute;
  text-align: center;
  width: 100%;
  pointer-events: none;

  -webkit-transition: height 0.25s ease-in-out;
  transition: height 0.25s ease-in-out;

  &--uploading {
    height: 100%;

    .iconButton {
      display: none;
    }
  }
}

.MediaMessageBuilder .fileChanger .iconButton {
  margin: auto;
}


.MessageBuilder .DelayMessageBuilder {
  padding:0;
  background:none;
  border-radius:0;
  .placeholder {
    position:absolute;
    left:10px;
    padding-top:12px;
    color:#aaaaaa;
  }
  input {
    padding: 12px 30px 12px 12px;
    border-radius:20px;
    width:222px;
    padding-left:40px;
    border: 4px #eaeaea dashed;
  }
}

.newEmojiPicker {
  position: absolute;
  z-index: 99999;
}

/* TextMessage */
.Message.TextMessage {
  border: 1px #eaeaea solid;
  overflow: visible !important;
  padding: 0; /* Must override .Message */
  width: 222px;
  .emojiPickerIcon {
    position: relative;
    width:30px !important;
    height:30px !important;
    top:30px;
    right:2px;
  }
}

.Message.QuestionMessage {
  padding: 0;
  overflow: visible !important;

  .emojiPickerIcon {
    width:30px !important;
    height:30px !important;
    top:28px;
    right:3px;
  }
}

.Message.OptionPart {
  padding: 10px;

  .OptionPart__divider {
    border-bottom: 1px dashed black;
    padding-bottom: 15px;
  }
}

.Message.TextMessage .buttonContainer, .Message.CarouselMessage .buttonContainer {
  .emojiPickerIcon {
    width:30px !important;
    height:30px !important;
    top:9px;
    right:auto;
    left:35px;
    color: black !important;
    position: absolute;
  }
}


.Message .messageText {
  border: none;
  background-color: transparent;
  height: 65px; /* Needs to be kinda tall to give the modal room */
  padding: 12px 30px 12px 12px;
  resize: none;
  width: 100%;

}

.ButtonMessage .buttonContainer {
  background: #fff;
  color: #0084ff;
  border-top: 1px #eaeaea solid;
  border-top: 1px #eaeaea solid;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  overflow: hidden;
}

.CarouselCard .buttonContainer {
  border-top: 0;
}

/* The "Add a button" button will be there before the message is a
   ButtonMessage */
.Message .messageButton {
  border: none;
  display: block;
  height: auto;
  padding: 12px;
  width: 100%;
  text-align: right;

}

.Message .buttonContainer .addButtonButton {
  border-top: 1px #eaeaea dashed;
  color: #999;
}

.Message .addButtonButton {
  cursor: pointer;
  text-align: center;
}

.inputButtonContainer {
  position: relative;
}

.Message {
  .inputButtonContainer:not(:first-of-type) {
    border-top: 1px solid #eaeaea;
  }

  &.CarouselMessage {
    .inputButtonContainer {
      border-top: 1px solid #eaeaea;
    }
  }
}

.inputButtonContainer .inputButtonSettings {
  color: #ccc;
  cursor: pointer;
  left: 12px;
  position: absolute;
  top: 9px;
  z-index: 1;
}

/* CarouselMessage */
.MessageBuilder .CarouselMessage {
  background: none;
  border-radius: 0;
  min-height: 150px;
  left: -18%; /* This is important */
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 20px 0 18%;
  position: relative;
  white-space: nowrap;
  width: 300px;
  z-index: 1;

  & + .profilePic {
    /* Doubt this looks good on much other than Chrome, might want to remove it */
    bottom: 22px;
  }
}
.CarouselContainer {
  position:relative;
  clear: left;
}

.CarouselArrow {
  position:absolute;
  background:#EAEAEA;
  right:0;
  top:100px;
  width:30px;
  height:30px;
  z-index:2;
  cursor:pointer;
  padding-top:3px;
  text-align: center;
  border:1px solid #333333;
  color:#333333;
  user-select: none;
  &.CarouselArrowLeft {
    left:0;
    right:auto;
    display:none;
  }
  &.disabled {
    display:none;
  }
}

.CarouselMessage .CarouselCard {
  background: #fff;
  border: 1px #ebebeb solid;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: inline-block;
  margin-right: 5px;
  min-height: 148px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 222px;
  vertical-align: top;

  .sheetToBot & {
    margin-bottom: 1em;
  }
}

// @todo would love to remove these eventually, but auto-feed final card stuff relies on them
.buttonCount1 .CarouselCard {
  height: 222px;
}

.buttonCount1.squareImage .CarouselCard {
  height: 317px;
}

.buttonCount2 .CarouselCard {
  height: 262px;
}

.buttonCount2.squareImage .CarouselCard {
  height: 357px;
}

.buttonCount3 .CarouselCard {
  height: 302px;
}

.buttonCount3.squareImage .CarouselCard {
  height: 397px;
}

.CarouselCard.AddCardCard {
  text-align: center;
}

.buttonCount1 .AddCardCard {
  line-height: 222px;
}

.buttonCount1.squareImage .AddCardCard {
  line-height: 317px;
}

.buttonCount2 .AddCardCard {
  line-height: 262px;
}

.buttonCount2.squareImage .AddCardCard {
  line-height: 357px;
}

.buttonCount3 .AddCardCard {
  line-height: 302px;
}

.buttonCount3.squareImage .AddCardCard {
  line-height: 397px;
}

.CarouselAspectRatio {
  margin-top: 10px;

  button {
    border: 0;
    background: none;
  }
}

.CarouselCard .cardImageContainer,
.CarouselCard .cardImagePlaceholder {
  background: #eaeaea;
  text-align: center;
  position: relative;
}

.CarouselCard .cardImagePlaceholder {
  line-height: 148px;
  overflow: hidden;
}

.CarouselCard .cardImage {
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
}

.CarouselCard .cardImageContainer .cardImageChanger {
  background: rgba(255,255,255,0.5);
  bottom: 0;
  left: 0;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  width: 100%;
  pointer-events: none;
}

.CarouselCard .cardBody {
  height: 55px;
  padding: 12px;
}

.CarouselCard .cardTitle,
.CarouselCard .cardSubTitle {
  border: 0;
  display: block;
  font-size: 10px;
  width: 100%;
}

.CarouselCard .cardTitle {
  font-size: 12px;
  font-weight: 600;
}

.messageButton::-ms-clear {
  display: none !important;
}

.autoCarousel {
  &__settingsList {
    padding-left: 0;
  }
}

/* Message-level Modals */
.messageModal {
  background: rgba(40, 44, 55, 0.98);
  color: #FFF;
  border-radius: 0;
  display: none;
  min-height: 1000px;
  height: 100%;
  left: 0;
  overflow: auto;
  padding: 2px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5500;
}

.messageModal.contentModal {
  position: fixed;
  z-index: 5000;
  display: block;
  min-height: 100%;
}

.messageModal.modalVisible {
  display: block;
}

.messageModal .modalContents {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  width: 50%;
  margin: 100px auto;


  .title {
    font-size: 3em;
  }

  .subTitle {
    font-size: 2em;
  }

  .closeButton {
    position:absolute;
    top: 5%;
    right: 15%;
    cursor:pointer;
  }

  .closeButtonBackground {
    position: absolute;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .closeButtonIcon {
    position: absolute;
    top: 8px;
    left: 8px;
    color: #0084FF;
  }

  .Select-input {
    width: 100%;

    input[role='combobox'] {
      width: 100% !important;
    }
  }
}

.messageDropdown {
  &__input  {
    background: transparent;
    color: #FFF;
    width: 90%;
    border: 0;
    font-size: 1.5em;
  }

  &__select {
    background: transparent;
    color: #FFF;
    width: 100%;
    border: 0;
    font-size: 1.5em;
  }
}

.inputSplitter {
  display: flex;

  .btn {
    margin-right: 20px;
  }
}

.pillSelector {
  border: 1px #eaeaea solid;
  border-radius: 9px;
  margin: 0;
  padding: 0;
}

.pillSelector .pill {
  border-right: 1px #eaeaea solid;
  color: #0084ff;
  cursor: pointer;
  font-size: 1em;
  margin: 0;
  padding: 6px;
  text-align: center;
  width: 25%;
}
.pillSelector.twoPills .pill {
  width:50%;
}

.pillSelector .pill:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.pillSelector .pill:last-child {
  border-right: 0;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.pillSelector .pill.active {
  background: #0084ff;
  color: #fff;
}

.pillSelector .pill input {
  display: none;
}

.pillContents input,
.pillContents select {
  width: 100%;
}

.qaButtonContainer {
  position: relative;
  height: 25px;
  margin-bottom: 8px;
}

.qaMessage {
  position: absolute;
  top: 3px;
  left: 37px;
  color: #A9A9AD;
}

.qaSettings {
  position: absolute;
  top: 0;
  left: 10px;
  color: #A9A9AD;
}

.addNewFeed {
  top: -8px;
  left: 10%;
}

.submitFeed {
  margin-right: 5px;
  left: 10%;
  top: 60px;
}

/* Utility classes */
.pvs {
  padding: 3px 0;
}

.pvm {
  padding: 6px 0;
}

.pvl {
  padding: 12px 0;
}

.refreshRow {
  text-align:right;
  display-inline:block;

  button{
    margin: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .categoryButtonContainer {
    flex: 2;
  }

  .newCategoryContainer {
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .modalText {
    font-size: 1.2em !important;
  }

  .modalError {
    font-size: 1.2em !important;
  }

  .messageModal .modalContents {
    width: 75%;
  }

  .newCategoryContainer {
    .createName {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 800px) {
  .chooseBucket .modalText .bucketHelp {
    display: none;
  }

  .chooseTag .modalText .tagHelp {
    display: none;
  }

  .categoryVisible {
    display: block;
  }

  .categoryContainer {
    width: 70%;
  }

  .categoryButtonContainer {
    text-align: right;
    position: relative;
    width: 30%;
    top: -42px;
    left: 70%;
    margin-bottom: -42px;
  }

  .newCategoryContainer {
    width: 100%;
  }
}

.navigationMode {
  .inputButtonContainer, .QuickReply {
    cursor: pointer;

    input.messageButton, input.quickReplyInput {
      pointer-events: none;
    }
  }

  .QuestionMessageContainer {
    .answerButton {
      margin: 0px 8% 3px 18%;
      padding: 10px;
      border-radius: 15px;
      text-align: center;
      cursor: pointer;

      &.valid {
        border: 1px solid darkgray;
        background-color: white;
      }

      &.invalid {
        border: 1px solid darkgray;
        background-color: white;
      }
    }
  }
  .Message .messageButton {
    text-align: center;
  }

  .inputButtonContainer input {
    cursor: pointer;
  }
}
