
.sequentialrandom-builder {
    font-size: 12pt;
    max-width: 1024px;

    $border-color: #d8d8d8;

    &__messagelist {
        list-style: none;
        padding: 0;
    }

    .radiobutton__label {
        margin-left: 15px;
    }

    &__type {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__typeoptions {
        display: inline-block;
    }

    &__typelabel {
    }

    &__addbutton {
        margin: 0;
    }

    &__message {
        display: flex;
        align-items: center;
        height: 51px;

        padding: 5px 0;
        border-top: 1px solid $border-color;

        &:last-child {
            border-bottom: 1px solid $border-color;
        }

        & * {
            vertical-align: middle;
        }

        .Select--single {
            flex: 1;
            max-width: 500px;
        }
    }

    &__ruler {
        margin: 10px 0 15px 0;
        border-color: $border-color;
    }

    &__movecontrols {
        display: block;
        width: 50px;
    }

    &__movebutton {
        vertical-align: top;
        background: none;
        border: none;
        display: block;
        padding: 0;
        margin: 0 auto;

        .material-icons {
            font-size: 20px;
        }
    }

    .builder__deletebutton, &__deletebutton {
        margin: 0 0 0 auto;
    }
}
