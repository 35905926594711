.login-page {
    height: 100%;
    text-align: center;
    min-height: 100vh;

    .form-control,
    .form-group .form-control {
        background-image: linear-gradient(#0083ff, #0084ff), linear-gradient(rgba(#000, 0.13), rgba(#000, 0.13));
    }

    p.description,
    p.reset-description {
        color: white;
        font-size: 16px;
        font-weight: 600;

        &.wrap {
            width: 420px;
            margin: auto;
        }
    }

    p.reset-description {
        color: black;
        font-weight: normal;
        font-size: 16px;
    }

    .modal-content {
        text-align: left;
        background: #fff;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
        border-radius: 5px;

        p {
            font-size: 16px;

            &.should-look {
                padding-bottom: 10px;
            }
        }

        .permissions {
            width: 100%;
            border: 1px solid #ccc;
        }
    }

    &__login-selector-link {
        text-align: center;
        font-size: 16px;

        a {
            color: $brand-primary;

            i {
                color: #837616;
            }
        }

        &-sso,
        &-signup,
        &-password {
            padding-top: 25px;
        }

        &-signup {
            font-size: 14px;
        }
    }

    .modal {
        .alert {
            margin-bottom: 0;
        }

        &.short {
            padding-top: 25px;

            .modal-body {
                text-align: center;
            }
        }

        padding-top: 20px;
    }

    .form-group {
        margin-top: 0;
        margin-bottom: 0;
    }

    .modal-footer {
        margin-top: 20px;
        text-align: left;
        padding: 0;

        .small {
            line-height: 36px;
        }
    }

    .modal-dialog {
        padding-bottom: 80px;

        @media only screen and (min-device-height: 768px) {
            margin: 0 auto;
            padding-top: 80px;
        }

        @media (min-width: 768px) {
            width: 636px;
        }

        .logo {
            text-align: center;
        }

        .tagline {
            font-family: Poppins, sans-serif;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            color: #444;
            margin-top: 15px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        p {
            color: #333;
            font-weight: normal;

            a {
                color: $brand-primary;
            }
        }
    }

    .errors {
        background-color: #e84e50;

        p {
            color: white;
        }

        .btn {
            color: #0084ff;
        }
    }

    .password-confirm {
        font-size: 110px;
        color: #0084ff;
    }

    &.invert {
        p.description {
            color: #333;
            font-weight: normal;
            line-height: 2;

            a {
                font-weight: 600;
                color: inherit;
            }
        }
    }
}

@media only screen and (max-width: 370px) {
    .login-page h2 {
        font-size: 14px;
    }
}

.forgot-password .help-block {
    color: red;
}
