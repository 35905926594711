.page-followup {
    .table > thead > tr > th {
        line-height: inherit;

        a {
            color: #0084ff;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
