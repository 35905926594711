.empty-state {
    width: 60%;
    text-align: center;
    margin: 20px auto;

    &__title {
        font-size: 4rem;
    }

    &__subtitle {
        font-size: 2rem;
    }

    &__blurb {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    &__help {
        margin-top: 40px;
    }

    &__video {
        margin-top: 4em;
    }

    p {
        font-size: 1.5rem;
    }
}
