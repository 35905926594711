
.side-menu {
    .menu-item {
        .material-icons {
            display: inline-block;
            vertical-align: top;
            font-size: 18px;
        }
    }

    &.left {
        z-index: 10;
    }

    .menuitem {
        &--beta:before, &--botadmin:before, &--admin:before {
            content: '\3b2';
            font-weight: 600;
            position: absolute;
            right: 10px;
        }

        &--beta:before {
            color: #0084ff;
        }

        &--botadmin:before {
            color: orange;
        }

        &--admin:before {
            color: red;
        }
    }

    .menu-favourites-container {
        .no-favourites {
            color: whitesmoke;
        }
    }

    .icon__favourite {
        color:white;
        position: absolute;
        left: 2px;
        font-size: 20px;
        z-index: 9999;
        line-height: 24px;
        opacity: .5;
        display: none;
    }
    .icon__favourite:hover {
        opacity: 1;
    }
    li:hover .icon__favourite {
        display: block;
    }

    .nav-pills:not(.menu-favourites):last-child {
        padding-bottom: 50px;
    }
}

.nav > li > a {
    padding: 4px 15px 4px 25px;
}

.left {
    position: fixed;
    top:80px;
    left: 0;
    background: rgb(38,7,72);
    background: linear-gradient(270deg, rgba(38,7,72,1) 0%, rgba(15,0,37,1) 35%);
    width: 200px;
    height: calc(100% - 80px);
    border-right: 1px solid #ddd;
    padding: 20px 20px 0 20px;
    overflow-y:auto;
    li {
        margin-left:-15px !important;
        a {
            color:#B5C1E2;
            &.todo {
                color:#1a1a1a;
            }
            &:hover {
                color:$brand-primary;
            }
        }
    }
}

.right {
    margin: 80px 0 0 200px;
}

.left h6 {
    color: #fff;
    font-size: 13px;
    border-top: 1px solid $brand-secondary;
    padding: 15px 0 0 10px;
    font-weight: 600;
}
.compare-icon i {
    font-size:30px;
    color:$brand-primary !important;
    margin-top:-7px;
}

.left-icon {

}
@media(max-width:750px) {
    .left-icon {
        display:inline-block;
        i {
            margin:0;
        }
    }
    .right {
        margin:80px 0 0;
    }
    .left {
        // opacity:0;
        width:100%;
        bottom:0;
        height:auto;
        z-index:999;
        display:block;
        display:none;
        &.active {
            display:block;
        }
    }
}
