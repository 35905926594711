.page-admin-user-edit,
.page-adminbottemplate,
.page-admin-organisation-delete,
.page-api-access-create,
.page-bot-delete,
.page-bot-subscription,
.page-user-settings-security,
.page-user-settings-account-details,
.page-user-settings-invites,
.page-placeholder-template,
.page-facebook-workplace-onboarding-switch,
.page-deep-link-generator {
    .form-group .help-block {
        display: block;
    }
}

.page-bot-delete,
.page-bot-subscription,
.page-facebook-workplace-onboarding-switch {
    .form-group {
        padding-bottom: 15px;
    }
}

.page-user-enable-2fa,
.page-content-edit,
.page-invite-user-permissions,
.page-facebook-workplace-onboarding-credentials {
    .help-block {
        color: red;
    }
}

.page-teams-broadcast-settings {
    .form-group .help-block {
        display: block !important;
        position: relative !important;
        margin-top: 3px !important;
        margin-bottom: 10px !important;
    }
}


.page-keyword-update,
.page-keyword-list {
    #keyword > .help-block {
        margin-top: 12px;
        background-color: #EA5A5A;
        color: rgba(255, 255, 255, 0.84);
        padding: 15px;
        margin-bottom: 20px;
    }
}

.page-content-menu-builder {
    .form-group {
        margin: 10px 0 20px 0 !important;
    }
    .form-group:last-child {
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }
}
