.table-striped > tbody > tr:nth-of-type(odd) {
	background: #F6FBFF;
}

.panel,
body .container .well,
body .container .jumbotron,
body .container-fluid .well,
body .container-fluid .jumbotron {
	border-radius: 5px;
	border: 1px solid rgba(#000000, 0.2);
	box-shadow: none;
}

.btn.btn-raised:not(.btn-link),
.btn-group-raised .btn:not(.btn-link),
.input-group-btn .btn.btn-raised:not(.btn-link),
.btn-group-raised .input-group-btn .btn:not(.btn-link) {
	box-shadow: none;
}

.table > thead > tr,
.table > tbody > tr {
	& > th {
		border-bottom-width: 1px;
		height: 60px;
		line-height: 60px;
		&:first-child {
			padding-left: 20px;
		}
	}
	& > td {
		&:first-child {
			padding-left: 20px;
		}
	}
}

.form-control, .form-group .form-control {
	background-image: linear-gradient(#0084ff, #0084ff), linear-gradient(#333333, #333333);
}
::-webkit-input-placeholder, /* Chrome/Opera/Safari */
::-moz-placeholder, /* Firefox 19+ */
:-ms-input-placeholder, /* IE 10+ */
:-moz-placeholder { /* Firefox 18- */
  color: #333333;
}

label {
	color:#333333;
}
.form-group  {
	label {
		color:#333333;
	}
	input {
	}
}
