.page-onboarding.teams, .page-privacy-services.teams, .page-meta.teams {
  .boxed {
    border: #888 1px dashed;
    padding: 5px 2px;
    margin: 0 5px 0 2px;
  }

  .steps {
    li {
      line-height: 2.5em;
    }

    i {
      margin-left: 5px;
    }

    .upload {
      color: #6264a7;
      line-height: 1em;
      padding: 5px 6px;
    }

    .application-name {
      color: #6264a7;
    }

    img#app-id-help {
      border: 1px solid #aaa;
      border-radius: 2px;
      margin-top: 20px;
      width: 358px;
      display: block;
    }

    .analogue {
      &.search-box {
        color: #6e6e6e;
        background-color:#f3f2f1;
        padding:5px;
        margin-right:5px;
      }

      &.search-result-text {
        color: #6264a7;
      }

      &.modal-confirm-button {
        background-color:#6264a7;
        color:white;
        padding:5px 10px;
        border-radius:3px;
        margin-right:5px;
      }
    }
  }
}

.page-privacy-services {
    .page-privacy-services { margin-top: 40px; }
    .form-group input[type=file] { opacity: 1 !important; position: relative !important; width: 90% !important; }
    .form-group .help-block { position: relative !important; }
    .example-img { border: lightgray 1px solid; border-radius: 2px;}
    .group { margin-top: 30px; }
    .group .form-group { width: 90%; }
    .fa-trash { color: red; cursor: pointer; float: right; margin-top: -25px; z-index: 9999; }
    .delete-meta { display: none; }
    p { font-size: 14px; }
    ul { list-style-type: none; padding-left: 0; }
    ul > li { font-size: 14px; }
}

.page-meta.teams {
    #teams_meta_avatar { opacity: 1; position: relative; }
    #image-preview { margin-top: 10px; }
    .avatar-error-block { color: red; }
    .page-onboarding { margin-top: 40px; }
    .form-group input[type=file] { opacity: 1 !important; position: relative !important; }
    .form-group .help-block { position: relative !important; }
    .example-img { border: lightgray 1px solid; border-radius: 2px;}
    .group { margin-top: 30px; }
    .group > .boxed { margin-top: 10px !important; display: inline-block; width: 60%; font-size: 14px; }
    .fa-trash { color: red; cursor: pointer; }
    p { font-size: 14px; }
    ul { list-style-type: none; padding-left: 0; }
    ul > li { font-size: 14px; }
}
