.page-messageengagement {
  .label-info {
    font-size: 8px;
    margin-left: 5px;
    vertical-align: middle;
  }

  .deleted-message-row {
    display: none;
  }
}
