$border-color: #ccc;
$copy-color: #333;
$infoCircle-color: #999;

.widgets {
  min-height: 1000px;
  min-width: 676px;
  position: relative;

  &__loading {
    font-size: 1.5em;
  }

  .mui-tabs__bar li {
    cursor: pointer;

    a {
      cursor: inherit;
    }
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.analytics {
  position: relative;
  min-width: 918px;

  &__dateError {
    color: red;
    position: absolute;
    top: -1.5em;
    right: 0;
  }

  &__spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: 15px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner .6s linear infinite;
  }

  &__loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.85);
    z-index: 999;
  }

  &__loadingTitle {
    color: black;
    font-size: 18px;
    text-align: center;
    margin-top: 200px;
  }
}

.topLineStats {
  &__container {
    display: flex;
  }

  &__metricBox {
    flex: 1;
    border-left: 1px $border-color solid;
    border-top: 1px $border-color solid;
    border-bottom: 1px $border-color solid;
    min-height: 160px;
    padding: 15px;

    &:first-child {
      border-radius: 4px 0 0 0;
    }

    &:last-child {
      border: 1px $border-color solid;
      border-radius: 0 4px 0 0;
    }
  }

  &__delta {
    font-size: 1.2em;

    .statNeutral {
      color: #999;
    }

    .statUp {
      color: #24B685;
    }

    .statDown {
      color: #EA5A5A;
    }
  }

  &__metricTitle {
    position: relative;
    margin-top: 10px;
    font-size: 1.2em;
    color: $copy-color;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif
  }

  &__infoCircle {
    position: relative;
    color: $infoCircle-color;
    margin-left: 5px;
    font-size: 1.2em;
    top: 3px;
  }

  &__value {
    color: #0084FF;
    font-size: 2.5em;
  }

  &__toolTip {
    position: absolute;
    top: 50px;
    left: 55px;
    z-index: 99;
    width: 150px;
    background-color: white;
    border: 1px solid $border-color;
    padding: 5px 10px;
    font-size: 0.8em;
    opacity: 0;
    transition: 0.6s opacity;
  }

  &__upper {
    height: 110px;
    min-width: 100px;
  }
}

.journey {
  &__error {
    color: red;
  }

  .btn-outline {
    border: 1px solid #0084FF;
    color: #0084FF;
  }

  &--picker {
    margin-top: 2em;
    margin-bottom: 1em;

    .btn {
      margin-top: 3px;
    }
  }
}

.journeyContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.messageJourneyDataWidget {
  flex: 1 1 auto;
  border: 1px solid $border-color;
  border-radius: 5px;
  margin: 20px 2% 20px 0;
  position: relative;

  .analytics__spinner:before {
    width: 40px;
    height: 40px;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner .6s linear infinite;
  }

  padding: 15px;
  width: 20%;
  max-width: 20%;
  min-height: 275px;
  min-width: 225px;

  &--button {
    text-align: center;
  }

  .MessageResults {
    margin-top: 2em;
    text-align: center;
  }

  &__title {
    font-weight: 600;
  }

  &__error {
    padding-top: 40px;
    color: red;
  }
}

.addWidget {
  flex: 1 1 auto;
  text-align: center;
  border: 1px solid $border-color;
  border-radius: 5px;
  margin: 20px 2% 20px 0;

  padding: 115px 0 0 0;
  width: 20%;
  max-width: 20%;
  min-height: 281px;
  min-width: 225px;

  &__plus {
    color: $border-color;
    font-size: 4rem;
  }

}

.graph {
  &__container {
    border-left: 1px $border-color solid;
    border-right: 1px $border-color solid;
    border-bottom: 1px $border-color solid;
    border-radius: 0 0 4px 4px;
    position: relative;
  }

  &__title {
    position: relative;
    z-index: 1;
    top: 25px;
    left: 40px;
    font-size: 1.2em;
    color: $copy-color;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif

  }

  &__infoCircle {
    color: $infoCircle-color;
    vertical-align: middle;
    margin-left: 5px;
    font-size: 1.2em;
    top: 3px;
  }

  &__toolTip {
    position: absolute;
    top: 0;
    left: 140px;
    z-index: 99;
    width: 150px;
    background-color: white;
    border: 1px solid $border-color;
    padding: 5px 10px;
    font-size: 0.8em;
    opacity: 1;
    transition: 0.6s opacity;
  }
}

.subscribers {
  &__container {
    display: flex;
    right: 0;
    position: absolute;
    top: 20px;

    .stat {
      font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif;
      font-size: 1.2em;
    }
  }
}

.datePicker {
  &__container {
    display: flex;
    position: absolute;
    top: 80px;
    right: 0;
  }

  &__label {
    margin: 0 10px;

    &+div {
      position: relative;
      display: inline-block;

      &> .react-datepicker-popper {
        z-index: 2147483647;
      }
    }
  }
}
