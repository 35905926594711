
.clippy {
	background: #eee;
	background-image: linear-gradient(#fcfcfc, #eee);
	border: 1px solid #d5d5d5;
	border-radius: 3px;

	&:hover {
		background-color: #ddd;
		background-image: linear-gradient(#eee, #ddd);
		border-color: #ccc;
	}
}
