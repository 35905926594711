
.page-broadcasts {
  .info-desc {
	margin-bottom: 10px;
  }

  .timezone-selector {
	.widget {
	  display: inline-block;
	  width: 200px;
	}
  }

  #broadcast-list th {
    white-space: nowrap;
  }

  #broadcast-list > tbody > tr > td {
    vertical-align: unset;
  }

  @import './_mui-tabs';

}
