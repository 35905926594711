
.page-schedule {
	.date-time-container,
    .ends-at-container {
		display: inline-block;
		.form-inline {
			display: inline-block;
		}
	}

	.week-only .checkbox {
		display: inline-block;
		margin-right: 10px;
	}


    .user-attribute-container .form-group {
        width: calc((100% - (15px * 3) - 64px) / 3);
        display: inline-block;
        margin-top: 0;
    }
    .user-attribute-container .form-group:not(:last-child) {
        margin-right: 15px;
    }
    .user-attribute-container .action-buttons {
        display: inline-block;
        color: #555;
    }
    .user-attribute-container .logic-desc {
        font-weight: 600;
        font-size: 16px;
        color: #555;
        text-transform: uppercase;
        margin-top: 15px;
    }
    .facebookMessageTag-container .form-group {
        margin-top: 0;
    }
    .target-container .form-group {
        margin-top: 0;
    }
    .target-container .form-group label {
        display: block;
    }
    .target-controls .form-group label:first-child {
        margin-top: 8px;
    }
    .target-controls {
        overflow: hidden;
        height: 0;
    }
    .summary {
        margin-top: 32px;
    }
    label.label-disabled {
        opacity: .5;
    }
    .form-group.has-error > label {
        color: #333!important;
    }


}
