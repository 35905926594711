@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/blue.css';
@import '@radix-ui/colors/white-alpha.css';

/* reset */
button {
    all: unset;
}

.DayPicker {
    display: inline-flex;
    background-color: var(--blue-6);
    border-radius: 4px;
    border: 1px solid rgb(0, 132, 255);
}
.DayPickerIcon {
    background-color: rgb(0, 132, 255);
    height: 45px;
    width: 45px;
    margin: 0;
    padding: 10px;
    font-size: 19px;
    line-height: 2;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: var(--white-a9);
}

.DayPickerItem {
    background-color: white;
    color: var(--black-a9);
    height: 45px;
    width: 45px;
    display: flex;
    font-size: 18px;
    line-height: 2;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    cursor: pointer;
}
.DayPickerItem:first-child {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.DayPickerItem:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.DayPickerItem:hover {
    background-color: var(--black-a1);
}
.DayPickerItem[data-state='on'] {
    background-color: var(--blue-5);
    color: var(--blue-11);
    font-size: 18px;
    font-weight: 600;
}
.DayPickerItem[data-state='on']:hover {
    background-color: var(--black-a4);
    color: var(--blue-1)
}
.DayPickerItem[data-state='off']:hover {
    background-color: var(--white-a10);
    color: var(--blue-11)
}
.DayPickerItem:focus {
    position: relative;
    box-shadow: 0 0 0 2px black;
}
